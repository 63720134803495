import { IUpdateTicketStatusInfoData, UN_TRIAGED_TEAM_NAMES } from '@copilot-dash/domain'
import { getRootCauseSelectOptionIds } from '../../TicketActionForm/utils'

export type ElementType<T extends ReadonlyArray<unknown>> = T[number]

const ADO_BUG_REASON_OPTIONS = {
  Resolved: [
    'As Designed',
    'Cannot Reproduce',
    'Copied to Backlog',
    'Deferred',
    'Duplicate',
    'Fixed',
    'Obsolete',
    'Will not Fix',
  ] as const,
  Closed: [
    'As Designed',
    'Cannot Reproduce',
    'Copied to Backlog',
    'Deferred',
    'Duplicate',
    'Fixed and verified',
    'Obsolete',
  ] as const,
} as const
export const PRIORITY_OPTIONS = [0, 1, 2, 3, 4] as const
const ADO_STATE_OPTIONS = ['New', 'Active', 'Resolved', 'Closed'] as const
export const COPILOT_DASH_STATE_OPTIONS = [
  'Active',
  'Closed - Not Actionable',
  'Closed - Fixed',
  'Closed - By Design',
] as const

export function mapADOStateToCopilotDashState(
  adoState: ElementType<typeof ADO_STATE_OPTIONS> | '' | undefined,
  reason?: string,
): ElementType<typeof COPILOT_DASH_STATE_OPTIONS> {
  switch (adoState) {
    case undefined:
    case '':
    case ADO_STATE_OPTIONS[0]:
    case ADO_STATE_OPTIONS[1]:
      return COPILOT_DASH_STATE_OPTIONS[0]
    case ADO_STATE_OPTIONS[2]:
      return COPILOT_DASH_STATE_OPTIONS[2]
    case ADO_STATE_OPTIONS[3]: {
      if (reason === 'Fixed and verified') {
        return COPILOT_DASH_STATE_OPTIONS[2]
      } else if (reason === 'As Designed') {
        return COPILOT_DASH_STATE_OPTIONS[3]
      }
      return COPILOT_DASH_STATE_OPTIONS[1]
    }
  }
}

export function mapCopilotDashStateToADOState(
  copilotDashState: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>,
  team: string,
): ElementType<typeof ADO_STATE_OPTIONS> {
  switch (copilotDashState) {
    case COPILOT_DASH_STATE_OPTIONS[0]:
      if (UN_TRIAGED_TEAM_NAMES.includes(team)) {
        return ADO_STATE_OPTIONS[0]
      } else {
        return ADO_STATE_OPTIONS[1]
      }
    case COPILOT_DASH_STATE_OPTIONS[1]:
    case COPILOT_DASH_STATE_OPTIONS[2]:
    case COPILOT_DASH_STATE_OPTIONS[3]:
      return ADO_STATE_OPTIONS[3]
    default:
      return ADO_STATE_OPTIONS[0]
  }
}

export function mapCopilotDashStateToADOReason(
  copilotDashState: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>,
): ElementType<(typeof ADO_BUG_REASON_OPTIONS)[keyof typeof ADO_BUG_REASON_OPTIONS]> | '' {
  switch (copilotDashState) {
    case COPILOT_DASH_STATE_OPTIONS[1]:
      return 'Obsolete'
    case COPILOT_DASH_STATE_OPTIONS[2]:
      return 'Fixed and verified'
    case COPILOT_DASH_STATE_OPTIONS[3]:
      return 'As Designed'
    default:
      return ''
  }
}

export function mapPostTicketInfoToTicketActionFormValue(postInfo: Partial<IUpdateTicketStatusInfoData>) {
  return {
    state: mapADOStateToCopilotDashState(postInfo.status, postInfo.reasoning),
    noActionableReason: postInfo.closedComment || 'N/A',
    priority: postInfo.priority,
    area: postInfo.teamId,
    rootCauseIDs: getRootCauseSelectOptionIds(postInfo.issueList),
    assignTo: postInfo.assignTo,
    customTags: postInfo.customTags,
  }
}
