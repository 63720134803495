import {
  ColumnConfigAssignTo,
  ColumnConfigCustomTag,
  ColumnConfigDateTime,
  ColumnConfigEmailAddress,
  ColumnConfigEmotionType,
  ColumnConfigEndpoint,
  ColumnConfigPriority,
  createColumnConfigResponse,
  ColumnConfigRing,
  ColumnConfigRootCause,
  ColumnConfigStatus,
  ColumnConfigTeamArea,
  ColumnConfigTenantName,
  ColumnConfigTicketId,
  ColumnConfigUserProfile,
  createColumnConfigUserUtterance,
  createColumnConfigVerbatimFeedback,
} from './index'
import { INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { ColDef } from 'ag-grid-community'

export const AllSearchTableColumns = (
  searchTextPrefix: SearchTextPrefixType,
  keyWords?: string,
): ColDef<INewTicketData>[] => [
  ColumnConfigTicketId,
  ColumnConfigDateTime,
  createColumnConfigUserUtterance(searchTextPrefix, keyWords),
  createColumnConfigResponse(searchTextPrefix, keyWords),
  createColumnConfigVerbatimFeedback(searchTextPrefix, keyWords),
  ColumnConfigEmotionType,
  ColumnConfigEmailAddress,
  ColumnConfigPriority,
  ColumnConfigRootCause,
  ColumnConfigStatus,
  ColumnConfigTeamArea,
  ColumnConfigTenantName,
  ColumnConfigRing,
  ColumnConfigEndpoint,
  ColumnConfigUserProfile,
  ColumnConfigAssignTo,
  ColumnConfigCustomTag,
  //TODO: add more columns here...
]
