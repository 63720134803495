import {
  ApiSearchTicketsTextPrefixType,
  ApiTicketType,
  ApiTicketsRequest,
  ApiTicketsWithTicketListRequest,
} from '@copilot-dash/api'
import { SearchTextPrefixType, getProductEndpoints } from '@copilot-dash/domain'
import { SearchTicketIdByAISearchAction } from './SearchTicketIdByAISearchAction'

import { ISearchTicketOptions } from './SearchTicketAction.types'
import { Times } from '@copilot-dash/core'
import { convertHasErrorMessages } from './utils/convertHasErrorMessages'
import { convertTriggeredSkillsFromKeyArray } from './utils/convertTriggeredSkillsFromKeyArray'
import { IDashStoreContext } from '../../IDashStoreContext'

interface IParams {
  readonly searchId: string
  readonly options: ISearchTicketOptions
  readonly partialAiSearchTicketIdList: string[]
  readonly partialAiSearchTriggered?: boolean
}

export class BaseTicketSearchAction {
  protected readonly context: IDashStoreContext
  private readonly SearchTicketIdByAISearchAction: SearchTicketIdByAISearchAction

  constructor(context: IDashStoreContext) {
    this.context = context
    this.SearchTicketIdByAISearchAction = new SearchTicketIdByAISearchAction(context)
  }

  protected async searchWithPartialAiSearch(options: ISearchTicketOptions): Promise<string[]> {
    const updatedOptions = {
      count: 2000,
      offset: 0,
      range: options.range,
      defaultRange: options.defaultRange,
      product: options.product,
      applications: options.applications,
      platforms: options.platforms,
      licenses: options.licenses,
      channel: options.channel,
      ring: options.ring,

      searchText: options.searchText,
      searchTextPrefix: options.searchTextPrefix,
      optionsSets: options.optionsSets,
      sliceIds: options.sliceIds,
      copilotExtensionIds: options.copilotExtensionIds,
      flights: options.flights,
      userId: options.userId,
      customTags: options.customTags,
    }
    const ticketList = await this.SearchTicketIdByAISearchAction.search(updatedOptions)
    const ticketIdList = ticketList.tickets.map((ticket) => ticket.ticketId)

    return ticketIdList
  }

  protected needToSearchPartialAiSearch = (options: ISearchTicketOptions): boolean => {
    // When users search with utterance/response/verbatim/optionsSets/sliceIds/copilotExtensionIds/flights/userId/customTags, we need to search with partial AI search
    const PartialAiSearchTriggered =
      options.searchText ||
      options.optionsSets ||
      options.sliceIds ||
      options.copilotExtensionIds ||
      options.flights ||
      (options.userId?.length ?? 0) > 0 ||
      (options.customTags?.length ?? 0) > 0

    return !!PartialAiSearchTriggered
  }

  protected createRequest(params: IParams): ApiTicketsWithTicketListRequest {
    const { partialAiSearchTicketIdList, partialAiSearchTriggered, options } = params
    //TODO: Align new rule with backend to handle triggered skill
    const triggeredSkills = convertTriggeredSkillsFromKeyArray(options.triggeredSkill)
    const hasErrorMessagesConditions = convertHasErrorMessages(options.hasErrorMessages)
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })
    const clientNames = getProductEndpoints(
      options.product,
      options.applications,
      options.platforms,
      options.licenses,
      options.meetingScenarios,
    )

    const requestBody: ApiTicketsRequest = {
      Count: options.count,
      Offset: options.offset,
      ClientNames: clientNames,
      ScenarioNames: options.channel,
      MetaData: [
        ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
        ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
        ...(options.hasUserConsent?.length ? [options.hasUserConsent.join('|')] : []),
        ...(options.isApology?.length ? [options.isApology.join('|')] : []),
        ...(options.hasVerbatim?.length ? [options.hasVerbatim.join('|')] : []),
        ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
        ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
        ...(triggeredSkills ?? []),
        ...(hasErrorMessagesConditions ?? []),
        ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
        ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
        ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
        ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
        ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
        ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
        ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
        ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
        ...(options.bizchatScenario?.length ? [options.bizchatScenario.join('|')] : []),
        ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
        ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
        ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
        ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
        ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
        ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
        ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
        ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
        ...(options.agentTypes?.length ? [options.agentTypes.join('|')] : []),
        ...(options.appTypes?.length ? [options.appTypes.join('|')] : []),
      ],
      //ModelLanguages: options.modelLanguages,//Updated ModelLanguages to Utterance Language(from VSO Tag)
      Rings: options.ring,
      From: from,
      To: to,
      TicketType: ApiTicketType.SearchTicket,
      TenantIds: options.tenantIds,
      Thumbs: options.thumbs?.length === 1 ? options.thumbs[0] : undefined,
      ExternalTicketId: options.ticketId,
      Priorities: options.priority?.map(Number) ?? [],
      DSATStatus: options.dSATStatus,
      DSATAssignedTo: options.dSATAssignedTo,
      PartialAiSearchTriggered: partialAiSearchTriggered,
    }

    return {
      searchId: params.searchId,
      SearchTicketStatus: params.options.ticketStatus,
      TeamName: params.options.teamName,
      IssueId: params.options.issueId,
      VsoAccount: params.options.vsoAccount,
      RecommendedRootCauseId: params.options.recommendedRootCauseId,
      ClusteringIssueId: params.options.clusteringIssueId,
      ClusteringBatchId: params.options.batchId,
      TeamId: params.options.teamId,
      request: requestBody,
      PartialAiSearchTicketIdList: partialAiSearchTicketIdList,
    }
  }

  protected generatePrefixType(options: ISearchTicketOptions): ApiSearchTicketsTextPrefixType {
    if (!options.searchText) return ApiSearchTicketsTextPrefixType.Unset
    if (options.searchTextPrefix === SearchTextPrefixType.Utterance)
      return ApiSearchTicketsTextPrefixType.OnlySearchUtterance
    if (options.searchTextPrefix === SearchTextPrefixType.Response)
      return ApiSearchTicketsTextPrefixType.OnlySearchResponse
    if (options.searchTextPrefix === SearchTextPrefixType.Verbatim)
      return ApiSearchTicketsTextPrefixType.OnlySearchVerbatim
    if (options.searchTextPrefix === SearchTextPrefixType.All)
      return ApiSearchTicketsTextPrefixType.SearchUtteranceOrResponseOrVerbatim
    return ApiSearchTicketsTextPrefixType.Unset
  }
}
