import { PromiseSnapshot } from '@copilot-dash/core'
import { useMemo } from 'react'
import { AnyData } from '../../../../../components/AnyData/AnyData'
import { AnyDataTab } from '../../../../../components/AnyData/AnyDataTypes'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function ConversationDataFromLegacy({ ticketId, selectedTurnId }: IProps) {
  const turnSnapshot = app.store.use.getTicketTurnMetadata(ticketId, selectedTurnId)
  const conversation = app.store.use.getRawConversation(ticketId, selectedTurnId)

  const jsonData = useMemo(() => {
    return {
      traceId: selectedTurnId,
      conversation: getFromSnapshot(conversation),
      interaction: turnSnapshot.data?.raw,
    }
  }, [conversation, selectedTurnId, turnSnapshot.data])

  return <AnyData data={jsonData} options={{ tabs: [AnyDataTab.Json] }} />
}

function getFromSnapshot(snapshot: PromiseSnapshot<unknown>) {
  switch (snapshot.status) {
    case 'waiting':
      return '(Waiting for data...)'
    case 'done':
      return snapshot.data
    case 'error':
      return '(Empty)'
  }
}
