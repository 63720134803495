import { Text } from '@fluentui/react-components'
import { ErrorView } from '../../../../components/Error'

interface IProps {
  readonly tenantId: string | undefined
  readonly tenantName: string | undefined
}

export function TicketScreenBarTicketTenant({ tenantId, tenantName }: IProps) {
  if (tenantName) {
    return <ContentByName tenantName={tenantName} />
  }

  if (tenantId) {
    return <ContentById tenantId={tenantId} />
  }

  return (
    <Text>
      <Text weight="semibold">Tenant: </Text>
      {<ErrorView.Custom type="inline" level="WARNING" message="N/A" />}
    </Text>
  )
}

function ContentById({ tenantId }: { tenantId: string }) {
  const snapshot = app.store.use.getTenant(tenantId)
  if (snapshot.data) {
    return <ContentByName tenantName={snapshot.data.tenantName} />
  }

  return null
}

function ContentByName({ tenantName }: { tenantName: string }) {
  return (
    <Text>
      <Text weight="semibold">Tenant: </Text>
      <Text>{tenantName}</Text>
    </Text>
  )
}
