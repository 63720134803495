import { ApiConversationGroup1, ApiConversationGroup2 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { useMemo } from 'react'
import { AnyData } from '../../../../../components/AnyData/AnyData'
import { AnyDataTab } from '../../../../../components/AnyData/AnyDataTypes'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function ConversationDataFromGroup({ ticketId, selectedTurnId }: IProps) {
  const turnSnapshot = app.store.use.getTicketTurnMetadata(ticketId, selectedTurnId)
  const group1 = app.store.use.getRawConversationGroup1(ticketId, selectedTurnId)

  const jsonData = useMemo(() => {
    return {
      traceId: selectedTurnId,
      conversation: getDataFromConversation(group1),
      interaction: turnSnapshot.data?.raw,
    }
  }, [group1, selectedTurnId, turnSnapshot.data])

  return <AnyData data={jsonData} options={{ tabs: [AnyDataTab.Json] }} />
}

function getDataFromConversation(snapshot: PromiseSnapshot<ApiConversationGroup1 | ApiConversationGroup2>) {
  switch (snapshot.status) {
    case 'waiting':
      return '(Waiting for data...)'
    case 'done':
      return snapshot.data.conversation
    case 'error':
      return '(Empty)'
  }
}
