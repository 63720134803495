import { IRootCauseList } from '@copilot-dash/domain'
import { Button, Divider, mergeClasses, Option, Text } from '@fluentui/react-components'
import { AddRegular, CheckmarkRegular, ChevronUpRegular } from '@fluentui/react-icons'
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../Layout'

import { useStyles } from '../RootCauses.styles'
import { motion } from 'framer-motion'
import { useDebounce } from '../../../../hooks/useDebounce'

interface IRootCauseSelectorProps {
  input: string
  setInput: (value: string) => void
  onChange: (value: string) => void
  disabledValues: string[]
  onCreateNewRootCauseClicked?: () => void
  defaultValueText?: string
  disabled?: boolean
  teamName?: string
  allowCreateNewRootCause?: boolean
  rootCauseGrouped?: Record<string, IRootCauseList>
}
export const RootCauseSelectorList: FC<IRootCauseSelectorProps> = memo(
  ({
    input,
    setInput,
    onChange,
    disabledValues,
    onCreateNewRootCauseClicked,
    teamName,
    allowCreateNewRootCause = false,
    rootCauseGrouped = {},
  }) => {
    const styles = useStyles()
    const debouncedFocusDisplayValue = useDebounce(input, 300)

    const [expandedTeams, setExpandedTeams] = useState<string[]>(() => {
      let defaultExpandedTeams: string[]
      if (disabledValues?.filter((item) => item).length > 0) {
        const teamArr = disabledValues.map((item) => {
          return (
            Object.entries(rootCauseGrouped).find(([_, rootCauseList]) => {
              return rootCauseList.some((rootCause) => item === rootCause.issueId)
            })?.[0] ?? ''
          )
        })
        defaultExpandedTeams = Array.from(new Set(teamArr))
      } else {
        const defaultTeam = teamName ?? Object.keys(rootCauseGrouped)[0] ?? ''
        defaultExpandedTeams = [defaultTeam].filter((item) => item !== '')
      }
      return defaultExpandedTeams
    })

    const sortedRootCauseGroups: Array<{
      teamName: string
      rootCauseList: IRootCauseList
      expanded: boolean
    }> = useMemo(() => {
      const sortedPairs = Object.entries(rootCauseGrouped).sort((a, b) => {
        if (a[0] === teamName) return -1
        if (b[0] === teamName) return 1
        return a[0].localeCompare(b[0])
      })
      return sortedPairs
        .map(([_teamName, rootCauseList]) => ({
          teamName: _teamName,
          rootCauseList:
            debouncedFocusDisplayValue.trim().length > 0
              ? rootCauseList.filter((rootCause) =>
                  rootCause.title.toLocaleLowerCase().includes(debouncedFocusDisplayValue.toLocaleLowerCase().trim()),
                )
              : rootCauseList,
          expanded: expandedTeams.includes(_teamName),
        }))
        .filter((item) => {
          return item.rootCauseList.length > 0
        })
    }, [rootCauseGrouped, expandedTeams, teamName, debouncedFocusDisplayValue])

    useEffect(() => {
      if (debouncedFocusDisplayValue.trim().length > 0) {
        setExpandedTeams(Object.keys(rootCauseGrouped))
      }
    }, [debouncedFocusDisplayValue, rootCauseGrouped, teamName])

    const onRootCauseChange = useCallback(
      (issueId: string) => {
        onChange(issueId)
        setInput('')
      },
      [onChange, setInput],
    )

    const filterOptions = useCallback(
      (optionsList: IRootCauseList) => {
        const filteredOptions = optionsList
          .filter((item) => {
            return item.title?.toLocaleLowerCase().includes(debouncedFocusDisplayValue.toLocaleLowerCase().trim())
          })
          .sort((a, b) => {
            if ((a.rootCauseStatus === 'Active' || '') && b.rootCauseStatus === 'Closed') {
              return -1
            }
            if (a.rootCauseStatus === 'close' && (b.rootCauseStatus === 'active' || '')) {
              return 1
            }
            const x1 = a.title!.toLocaleUpperCase().trim()
            const x2 = b.title!.toLocaleUpperCase().trim()
            if (x1 < x2) {
              return -1
            } else if (x1 > x2) {
              return 1
            } else {
              return 0
            }
          })

        return filteredOptions.length > 0 ? (
          filteredOptions.map((item) => {
            if (!item.title || !item.visible) {
              return
            }
            return (
              <Row key={item.issueId} style={{ width: '100%' }} onClick={() => onRootCauseChange(item.issueId)}>
                <Button
                  icon={disabledValues?.includes(item.issueId) ? <CheckmarkRegular /> : <Spacer width="20px" />}
                  appearance="subtle"
                  size="small"
                  style={{ width: '100%', justifyContent: 'flex-start', textAlign: 'left' }}
                >
                  <Option
                    value={item.issueId}
                    key={item.issueId}
                    text={item.title}
                    className={mergeClasses(
                      styles.optionText,
                      item.rootCauseStatus === 'Closed' ? styles.closedOptionText : '',
                    )}
                  >
                    {item.title}
                  </Option>
                </Button>
              </Row>
            )
          })
        ) : (
          <Text>No option match your search.</Text>
        )
      },
      [debouncedFocusDisplayValue, disabledValues, styles.optionText, onRootCauseChange, styles.closedOptionText],
    )

    const finalOptions = useMemo(() => {
      if (debouncedFocusDisplayValue.trim().length > 0 && sortedRootCauseGroups.length === 0) {
        return <Text>No option match your search.</Text>
      }
      if (debouncedFocusDisplayValue.trim().length === 0 && sortedRootCauseGroups.length === 0) {
        return <Text>No root cause available.</Text>
      }
      return sortedRootCauseGroups.map((group) => {
        return (
          <Column key={group.teamName}>
            <Row
              vAlign="center"
              className={styles.groupHeader}
              onClick={() => {
                if (expandedTeams.includes(group.teamName)) {
                  setExpandedTeams((pre) => pre.filter((name) => name !== group.teamName))
                } else {
                  setExpandedTeams((pre) => [...pre, group.teamName])
                }
              }}
            >
              <Text weight="semibold">{group.teamName}</Text>
              <Spacer />
              <motion.div animate={{ rotate: group.expanded ? 0 : 180 }}>
                <ChevronUpRegular fontSize={20} />
              </motion.div>
            </Row>
            {group.expanded ? filterOptions(group.rootCauseList) : null}
            <Divider />
          </Column>
        )
      })
    }, [debouncedFocusDisplayValue, sortedRootCauseGroups, styles.groupHeader, filterOptions, expandedTeams])

    return (
      <Column style={{ maxHeight: '50vh' }}>
        {allowCreateNewRootCause ? (
          <>
            <Row vAlign="center" className={styles.createAction} onClick={onCreateNewRootCauseClicked}>
              <Spacer width={4} />
              <AddRegular />
              <Spacer width={4} />
              <Text weight="semibold">Create new root cause</Text>
            </Row>
            <Divider />
          </>
        ) : null}
        {finalOptions}
      </Column>
    )
  },
)

RootCauseSelectorList.displayName = 'RootCauseSelectorList'
