import { SearchFilterFieldNames } from '@copilot-dash/domain'

export const AgentCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.agentTypes,
  SearchFilterFieldNames.appTypes,
  SearchFilterFieldNames.hasCopilotExtensionIds,
  SearchFilterFieldNames.copilotExtensionIds,
]

export const FeedbackSourceCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.applications,
  SearchFilterFieldNames.platforms,
  SearchFilterFieldNames.licenses,
  SearchFilterFieldNames.meetingScenarios,
  SearchFilterFieldNames.channel,
  SearchFilterFieldNames.customerTypes,
  SearchFilterFieldNames.ring,
]

export const FeedbackDetailCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.thumbs,
  SearchFilterFieldNames.hasVerbatim,
  SearchFilterFieldNames.hasUserConsent,
]

export const PromptDetailsCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.promptLanguages,
  SearchFilterFieldNames.groundedPrompts,
  SearchFilterFieldNames.invocationSlicers,
  SearchFilterFieldNames.invocationType,
]

export const QueryProcessingCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.triggeredSkill,
  SearchFilterFieldNames.hitAvalon,
  SearchFilterFieldNames.optionsSets,
  SearchFilterFieldNames.sliceIds,
  SearchFilterFieldNames.flights,
]

export const FeedbackHandlingCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.priority,
  SearchFilterFieldNames.dSATStatus,
  SearchFilterFieldNames.customTags,
  SearchFilterFieldNames.dSATAssignedTo,
]

export const CustomFilterCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.isTopi18N,
  SearchFilterFieldNames.isSTCAChina,
  SearchFilterFieldNames.hasGPTExtension,
  SearchFilterFieldNames.hasMessageExtension,
  SearchFilterFieldNames.hasConnector,
  SearchFilterFieldNames.isGCIntent,
  SearchFilterFieldNames.hasConnectorResult,
  SearchFilterFieldNames.semanticSearchType,
  SearchFilterFieldNames.experienceType,
  SearchFilterFieldNames.errorCode,
]

export const ResponseDetailsCardFilters: SearchFilterFieldNames[] = [
  SearchFilterFieldNames.isApology,
  SearchFilterFieldNames.hasCitation,
  SearchFilterFieldNames.hasErrorMessages,
  SearchFilterFieldNames.hasEntityCard,
  SearchFilterFieldNames.responseHeroType,
  SearchFilterFieldNames.responseLinkType,
]
