import { Button, Text, mergeClasses } from '@fluentui/react-components'
import { IFCRagResponseResultAnswerItemData, IFCRagResponseResultData } from '@copilot-dash/domain'
import { List24Regular, SparkleFilled } from '@fluentui/react-icons'
import { useFeedbackCopilotScreenActions, useFeedbackCopilotScreenState } from '../../store'

import { MarkdownView } from '../../../../components/Markdown/MarkdownView'
import { useStyles } from './CopilotMessageContext.styles'

/**
 * We use an empty space to display when the message content is empty to prevent the message content from collapsing.
 */
const EMPTY_PLACEHOLDER = `&nbsp;`

interface IProps {
  readonly defaultResponse?: string
  readonly queryStatus?: string
  readonly queryResult?: IFCRagResponseResultData
}

export function CopilotMessageContext({ defaultResponse, queryStatus, queryResult }: IProps) {
  const styles = useStyles()
  const actions = useFeedbackCopilotScreenActions()
  const ragAnswerId = useFeedbackCopilotScreenState((state) => state.clickedRagInfo?.answer_id)

  const onMessageClick = (ticket: IFCRagResponseResultAnswerItemData) => {
    actions.ragResultClick(ticket)
  }
  return (() => {
    // todo result list display
    if (queryStatus === 'QueryReady' && queryResult) {
      return (
        <div className={styles.ragResultPanel}>
          <MarkdownView className={styles.markdown}>{queryResult.response ?? ''}</MarkdownView>
          {/* <Text size={300} className={styles.ragResponseTitle}>
                {queryResult.response}
              </Text> */}
          {queryResult.response_references?.map((ticket, index) => (
            <div key={index} className={styles.ragResultItem}>
              <div className={styles.ragResultItemTitle}>
                <SparkleFilled style={{ marginRight: '8px' }} /> {/* Replace emoji with icon */}
                <Text style={{ fontWeight: 600 }}>{ticket.title}</Text>
              </div>
              <Text className={styles.ragResultItemSummary}>{ticket.summary}</Text>
              <div className={styles.ragResultItemButton}>
                <Button
                  appearance="outline"
                  icon={<List24Regular style={{ fontSize: '10px' }} />}
                  iconPosition="before"
                  className={mergeClasses(
                    styles.ragResultItemButtonLabel,
                    ragAnswerId === ticket.answer_id ? 'selected' : 'unselected',
                  )}
                  onClick={() => {
                    onMessageClick(ticket)
                  }}
                >
                  &nbsp;Tickets of &quot;{ticket.title}&quot;
                </Button>
              </div>
            </div>
          ))}
        </div>
      )
    } else {
      return <div className={styles.text}>{defaultResponse || EMPTY_PLACEHOLDER}</div>
    }
  })()
}
