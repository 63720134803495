import { Button } from '@fluentui/react-components'
import { useRef } from 'react'

const ORIGIN = 'https://ui.perfetto.dev'

interface IProps {
  readonly value: string | undefined
}

export function TicketFlameGraphButton({ value }: IProps) {
  const timerRef = useRef<NodeJS.Timeout>()

  const openTrace = (arrayBuffer: ArrayBuffer) => {
    const win = window.open(ORIGIN)
    window.addEventListener('message', (evt) => {
      if (evt.data !== 'PONG') return
      window.clearInterval(timerRef.current)
      win?.postMessage(arrayBuffer, ORIGIN)
    })
    timerRef.current = setInterval(() => win?.postMessage('PING', ORIGIN), 50)
  }

  const handleClick = () => {
    if (!value) {
      alert('Empty perf trace file!')
      return
    }
    const blob = new Blob([value], { type: 'plain/text' })
    blob.arrayBuffer().then((arrayBuffer) => {
      openTrace(arrayBuffer)
    })
  }

  return (
    <Button appearance="primary" onClick={handleClick} disabled={!value}>
      Open flame graph
    </Button>
  )
}
