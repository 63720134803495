import { INewTicketData, IRootCauseList, ITeamList, IUpdateTicketStatusInfoData } from '@copilot-dash/domain'
import { summarySatisfyPost } from '../../TicketActionForm/utils'
import { ReactiveFeedbackTicketAction } from './actions/ReactiveFeedbackTicketAction'
import { ReactiveRootCauseAction } from './actions/ReactiveRootCauseAction'
import { ReactiveTelemetryAction } from './actions/ReactiveTelemetryAction'
import { ITicketSummaryPanelActions } from './ITicketSummaryPanelActions'
import { ITicketSummaryPanelState } from './ITicketSummaryPanelState'
import { ITicketSummaryPanelStore } from './ITicketSummaryPanelStore'
import { AsyncLoader } from '@copilot-dash/core'

export function createTicketSummaryPanelActions(store: ITicketSummaryPanelStore) {
  return new TicketSummaryPanelActions(store)
}

class TicketSummaryPanelActions implements ITicketSummaryPanelActions {
  private readonly store: ITicketSummaryPanelStore

  private readonly reactiveFeedbackTicketAction: ReactiveFeedbackTicketAction
  private readonly reactiveRootCauseAction: ReactiveRootCauseAction
  private readonly reactiveTelemetryAction: ReactiveTelemetryAction

  private get setState() {
    return this.store.setState
  }

  constructor(store: ITicketSummaryPanelStore) {
    this.store = store

    this.reactiveFeedbackTicketAction = new ReactiveFeedbackTicketAction(store)
    this.reactiveRootCauseAction = new ReactiveRootCauseAction(store)
    this.reactiveTelemetryAction = new ReactiveTelemetryAction(store)
  }

  onStateChanged(state: ITicketSummaryPanelState, old: ITicketSummaryPanelState) {
    this.reactiveFeedbackTicketAction.onStateChanged(state, old)
    this.reactiveRootCauseAction.onStateChanged(state, old)
    this.reactiveTelemetryAction.onStateChanged(state, old)
  }

  updateTicketId(ticketId: string): void {
    this.setState({
      ticketId: ticketId,
    })
  }

  selectTurn(turnId: string): void {
    this.setState({
      selectedTurnId: turnId,
    })
  }

  setSummaryDraft(draft: Partial<IUpdateTicketStatusInfoData>, isModified: boolean): void {
    const satisfyPost = isModified && summarySatisfyPost.safeParse(draft).success
    this.setState({
      summaryDraftSubmittable: satisfyPost,
    })
    if (satisfyPost) {
      this.setState({
        summaryDraft: summarySatisfyPost.safeParse(draft).data,
      })
    }
  }

  updateTicketInfo(ticketInfo: INewTicketData | undefined): void {
    this.setState({
      ticketInfo,
    })
  }

  getCustomTags(ticketId: string): void {
    const asyncLoader = new AsyncLoader<string[]>()
    asyncLoader.submit({
      promise: app.store.actions.getTicketCustomTags(ticketId, true),
      onChanged: (snapshot) => {
        const newTicketInfo = { ...this.store.state.ticketInfo } as INewTicketData
        if (newTicketInfo) {
          const updatedTicketInfo = { ...newTicketInfo, customTags: snapshot.data as string[] }
          this.updateTicketInfo(updatedTicketInfo)
        }
        this.setState({
          customTagsSnapshot: snapshot,
        })
      },
    })
  }

  setFormExtraData(extraData: { teamList?: ITeamList; rootCauseList?: IRootCauseList }): void {
    this.setState({
      formExtraData: extraData,
    })
  }
}
