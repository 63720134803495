import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketSessionData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicket } from '../actions-raw-ticket-ods/getRawOdsTicket'
import { assertTicketNotExpired } from '../actions-ticket-assert/assertTicketNotExpired'
import { convertTicketSessionData } from './converters/convertTicketSessionData'

export function getTicketSession(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketSessionData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.session
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.session = snapshot
    },
    fetch,
  })

  async function fetch() {
    try {
      const item = await getRawOdsTicket(context, ticketId).promise
      return convertTicketSessionData(ticketId, item)
    } catch (error) {
      assertTicketNotExpired(context, ticketId)
      throw error
    }
  }
}
