import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicketInteractions } from '../actions-raw-ticket-ods/getRawOdsTicketInteractions'

export async function assertTicketTurnExists(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): Promise<void> {
  await assertFromODS(context, ticketId, messageId)
}

async function assertFromODS(context: IDashStoreContext, ticketId: string, messageId: string) {
  const interactions = await getRawOdsTicketInteractions(context, ticketId).promise
  const interaction = interactions.find((i) => i.MessageId === messageId)
  const statusCode = interaction?.Diagnostic?.Conversation?.StatusCode
  if (statusCode) {
    const error = TicketError.diagnostic(statusCode, ticketId)
    if (error) {
      throw error
    }
  }
}
