import { PromiseSnapshot } from '@copilot-dash/core'
import {
  ITicketMessageForBot,
  ITicketMessageForUser,
  ITicketTurnConversation,
  ITicketTurnConversationSourceType,
} from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawConversation } from '../actions-raw-ticket-chat/getRawConversation'
import { getRawConversationGroup1 } from '../actions-raw-ticket-chat/getRawConversationGroup1'
import { getRawOcvTicket } from '../actions-raw-ticket/getRawOcvTicket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { convertTicketChatTurnFromConversationGroup1 } from './converters/convertTicketChatConversationFromConversationGroup1'
import { convertTicketChatTurnFromConversationLegacy } from './converters/convertTicketChatConversationFromConversationLegacy'
import { getTicketLastTurnMessageId } from './getTicketLastTurnMessageId'

export function getTicketTurnConversation(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ITicketTurnConversation> {
  return context.getOrFetch<ITicketTurnConversation>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.conversation
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.conversation = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketTurnConversation> {
    const backend = fetchSydney()
    const backendData = await backend.catch(() => undefined)
    const backendError = await backend.then(() => undefined).catch((error) => error)

    const frontend = fetchFrontend()
    const frontendData = await frontend.catch(() => undefined)
    const frontendError = await frontend.then(() => undefined).catch((error) => error)

    if (backendData && frontendData) {
      return merge(backendData, frontendData)
    }

    const data = backendData ?? frontendData
    if (data) {
      return data
    }

    throw backendError ?? frontendError
  }

  async function fetchSydney(): Promise<ITicketTurnConversation> {
    try {
      return await fetchBackendFromConversationGroup1()
    } catch (error) {
      try {
        return await fetchBackendFromConversationLegacy()
      } catch {
        // do nothing
      }

      throw error
    }
  }

  async function fetchBackendFromConversationGroup1(): Promise<ITicketTurnConversation> {
    const response = await getRawConversationGroup1(context, ticketId, messageId).promise
    return convertTicketChatTurnFromConversationGroup1(messageId, response)
  }

  async function fetchBackendFromConversationLegacy(): Promise<ITicketTurnConversation> {
    const response = await getRawConversation(context, ticketId, messageId).promise
    return convertTicketChatTurnFromConversationLegacy(messageId, response)
  }

  async function fetchFrontend(): Promise<ITicketTurnConversation | undefined> {
    const lastMessageId = await getTicketLastTurnMessageId(context, ticketId).promise
    if (lastMessageId !== messageId) {
      return undefined
    }

    return context.enableV2Endpoint ? fetchFrontConversationFromV2() : fetchFrontendConversationFromOcv()
  }

  async function fetchFrontendConversationFromOcv(): Promise<ITicketTurnConversation | undefined> {
    const ocv = await getRawOcvTicket(context, ticketId).promise
    const utterance = ocv.AiContext?.Prompt
    const response = ocv.AiContext?.ResponseMessage

    return generate(utterance, response, 'OCV')
  }

  async function fetchFrontConversationFromV2(): Promise<ITicketTurnConversation | undefined> {
    const raw = await getRawV2Ticket(context, ticketId).promise
    const chat = raw.diagnosticContext?.chat?.find((chat) => chat.messageId === messageId)
    const utterance = chat?.utterance?.text
    const response = chat?.response?.text

    return generate(utterance, response, 'V2Metadata')
  }

  function merge(backend: ITicketTurnConversation, frontend: ITicketTurnConversation): ITicketTurnConversation {
    return {
      ...backend,
      utterance: mergeUtterance(backend.utterance, frontend.utterance),
      response: mergeResponse(backend.response, frontend.response),
    }
  }

  function mergeUtterance(
    backend: ITicketMessageForUser | undefined,
    frontend: ITicketMessageForUser | undefined,
  ): ITicketMessageForUser | undefined {
    if (backend && frontend && backend.content.text !== frontend.content.text) {
      return frontend
    }

    return backend ?? frontend
  }

  function mergeResponse(
    backend: ITicketMessageForBot | undefined,
    frontend: ITicketMessageForBot | undefined,
  ): ITicketMessageForBot | undefined {
    if (backend && frontend && backend.content.text !== frontend.content.text) {
      return frontend
    }

    return backend ?? frontend
  }

  async function generate(
    utterance: string | undefined,
    response: string | undefined,
    source: ITicketTurnConversationSourceType,
  ): Promise<ITicketTurnConversation | undefined> {
    if (!utterance || !response) {
      return undefined
    }

    return {
      id: messageId,
      timestamp: undefined,
      utterance: {
        timestamp: undefined,
        content: {
          text: utterance,
          markdown: undefined,
          adaptiveCard: undefined,
        },
      },
      response: {
        timestamp: undefined,
        content: {
          text: response,
          markdown: response,
          adaptiveCard: undefined,
        },
      },
      invocations: [],
      suggestions: [],
      source,
      raw: undefined,
    }
  }
}
