import { z } from 'zod'

export interface ApiSearchItem {
  readonly id: string
  readonly verbatim?: string
  readonly verbatimInEnglish?: string
  readonly utterance?: string
  readonly utteranceInEnglish?: string
  readonly response?: string
  readonly responseInEnglish?: string
  readonly userId?: string
  readonly createDateTime?: string
  readonly tenantId?: string
  readonly tags?: Array<string>
  readonly fullTags?: Array<string>
  readonly customTags?: Array<string>
  readonly emotionType?: string
  readonly hasUserConsent?: boolean
  readonly clientName?: string
  readonly scenarioName?: string
  readonly ring?: string
  readonly emailAddress?: string
  readonly invocationSlicers?: Array<string>
  readonly highlights?: Record<string, Array<string>>
}

export interface ApiAISearchResponse {
  readonly searchItems: Array<ApiSearchItem>
  readonly count: number
  readonly startIndex: number
  readonly stepNumber: number
  readonly emotionTypeCounts: Record<string, number | undefined>
}

export const apiSearchItemSchema = z.object({
  id: z.string(),
  verbatim: z.string().optional(),
  verbatimInEnglish: z.string().optional(),
  utterance: z.string().optional(),
  utteranceInEnglish: z.string().optional(),
  response: z.string().optional(),
  responseInEnglish: z.string().optional(),
  userId: z.string().optional(),
  createDateTime: z.string().optional(),
  tenantId: z.string().optional(),
  tags: z.array(z.string()).optional(),
  fullTags: z.array(z.string()).optional(),
  customTags: z.array(z.string()).optional(),
  emotionType: z.string().optional(),
  hasUserConsent: z.boolean().optional(),
  clientName: z.string().optional(),
  scenarioName: z.string().optional(),
  ring: z.string().optional(),
  emailAddress: z.string().optional(),
  invocationSlicers: z.array(z.string()).optional(),
  highlights: z.record(z.array(z.string())).optional(),
})

export const apiAISearchResponseSchema = z.object({
  searchItems: z.array(apiSearchItemSchema),
  count: z.number(),
  startIndex: z.number(),
  stepNumber: z.number(),
  emotionTypeCounts: z.record(z.union([z.number(), z.undefined()])),
})
