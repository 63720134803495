import {
  DefaultProductChannelMapping,
  INewTicketData,
  SearchTextPrefixType,
  TeamViewSubMenuIds,
} from '@copilot-dash/domain'
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  Button,
  Skeleton,
  SkeletonItem,
  Text,
} from '@fluentui/react-components'
import { EditRegular } from '@fluentui/react-icons'
import { compact, isNil } from 'lodash'
import * as React from 'react'
import { ErrorView } from '../../../components/Error'
import { Column, Row, Spacer } from '../../../components/Layout'
import { RootCauseCreateDialog } from '../../../components/RootCauseCreateDialog/RootCauseCreateDialog'
import { FieldNames, IFormData } from '../../../components/RootCauseCreateDialog/RootCauseCreateForm'
import { OverflowTabList } from '../../../components/TabList/OverflowTabList'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { TimeView } from '../../../components/Time/TimeView'
import { UserProfile } from '../../../components/User/UserProfile'
import { useToast } from '../../../hooks/useToast'
import { TeamRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { useTeamViewStore } from '../store'
import { DefaultRootCauseTab, EMPTY_TEAM_ID, LinkedDSATsTab, RootCauseTab } from '../store/const'
import { QuickSearch } from './QuickSearch'
import { TableLoading } from './TableLoading'
import { useStyles } from './TicketsTableForRootCause.styles'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { AGGridTable } from '../../../components/AGGridTable/AGGridTable'
import { ColDef } from 'ag-grid-community'

interface ITicketsTableForRootCauseProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
}

export const TicketsTableForRootCause: React.FC<ITicketsTableForRootCauseProps> = React.memo(
  ({ onRowClick, clickedTicketKey }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState<RootCauseTab>(RootCauseTab.LinkedDSATs)
    const [showPendingConfirmedDSATs, setShowPendingConfirmedDSATs] = React.useState(false)
    const linkedTicketsSnapshot = useTeamViewStore((state) => state.tickets.ticketListByRootCause)
    const recommendTicketsSnapshot = useTeamViewStore((state) => state.tickets.recommendTicketListByRootCause)
    const linkedTicketsHasMore = useTeamViewStore((state) => state.tickets.hasMoreLinkedTicketsForRootCause)
    const recommendTicketsHasMore = useTeamViewStore((state) => state.tickets.hasMoreRecommendTicketsForRootCause)
    const loadMoreLinkedTickets = useTeamViewStore((state) => state.tickets.loadMoreTicketListByRootCause)
    const loadMoreRecommendTickets = useTeamViewStore((state) => state.tickets.loadMoreRecommendTicketListByRootCause)
    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const selectedRootCauseId = useTeamViewStore((state) => state.rootCauses.selectedRootCauseId)
    const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
    const filter = useTeamViewStore((state) => state.tickets.filterForm)

    const styles = useStyles()
    const columnSetting = app.settings.teamViewColumns.use()
    const toast = useToast()

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)
    const productId = useTeamViewStore((state) => state.computed.productId)
    const rootCauseSnapshot = application.store.use((state) => state.team.rootCausesMap?.[`${productId}-${teamId}`])
    const selectedRootCause = React.useMemo(() => {
      return rootCauseSnapshot?.data?.rootCauseList.find((rootCause) => rootCause.issueId === selectedRootCauseId)
    }, [rootCauseSnapshot, selectedRootCauseId])

    const colDefs = React.useMemo(() => {
      const defaultColumns = AllSearchTableColumns(searchTextPrefix || SearchTextPrefixType.All, searchText)
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnSetting.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, searchText])

    const linkedDSATsCount = linkedTicketsSnapshot.status === 'done' ? linkedTicketsSnapshot.data?.ticketCount ?? 0 : ''
    const recommendedDSATsCount =
      recommendTicketsSnapshot.status === 'done' ? recommendTicketsSnapshot.data?.ticketCount ?? 0 : ''

    const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined) => {
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      useTeamViewStore.getState().toggleBatchUpdatePanel(!!(selectedItems && selectedItems.length > 0))
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
    }, [])

    const handleUpdateRootCause = React.useCallback(
      (data: IFormData) => {
        if (isNil(teamId) || teamId === EMPTY_TEAM_ID || !selectedRootCause) return Promise.resolve(undefined)
        let formattedDate = ''
        if (data[FieldNames.DUEDATE]) {
          const timeStr = String(data[FieldNames.DUEDATE])
          const date = new Date(timeStr)

          const year = date.getFullYear()
          const month = String(date.getMonth() + 1).padStart(2, '0')
          const day = String(date.getDate()).padStart(2, '0')

          formattedDate = `${year}-${month}-${day}`
        }

        const postData = {
          title: data[FieldNames.TITLE],
          description: data[FieldNames.DESCRIPTION] ?? '',
          priority: data[FieldNames.PRIORITY],
          rootCauseStatus: data[FieldNames.STATE],
          vsoAccount: selectedRootCause.vsoAccount,
          workItemId: selectedRootCause.issueId,
          teamId: teamId,
          owner: data[FieldNames.ASSIGNEDTO],
          originalCommittedDate: formattedDate,
          productId: productId,
        }
        return useTeamViewStore
          .getState()
          .rootCauses.updateTeamRootCause(postData)
          .then((resp) => {
            if (resp.errorMessage === '') {
              setDialogOpen(false)
              useGlobalStore.getState().updateRootCause(postData)
              productId &&
                application.store.actions.updateRootCauseByTeam(productId, teamId, {
                  rootCauseTitle: postData.title,
                  eTA: postData.originalCommittedDate,
                  owner: postData.owner,
                  issueId: postData.workItemId,
                  vsoAccount: postData.vsoAccount,
                  rootCauseStatus: postData.rootCauseStatus,
                  rootCausePriority: postData.priority,
                })
            } else {
              toast.showError('Failed to save', resp.errorMessage)
            }
          })
      },
      [teamId, selectedRootCause, productId, toast],
    )

    React.useEffect(() => {
      if (selectedRootCause?.issueId && selectedRootCause?.vsoAccount) {
        useTeamViewStore
          .getState()
          .tickets.fetchTicketListByRootCause(selectedRootCause.issueId, selectedRootCause.vsoAccount, filter)
        useTeamViewStore
          .getState()
          .tickets.fetchRecommendTicketListByRootCause(selectedRootCause.issueId, selectedRootCause.vsoAccount, filter)
      }
    }, [filter, selectedRootCause?.issueId, selectedRootCause?.vsoAccount])

    React.useEffect(() => {
      if (productId && teamId) {
        application.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
      }
    }, [teamId, productId])

    React.useEffect(() => {
      if (rootCauseSnapshot?.data?.rootCauseList) {
        const result = rootCauseSnapshot.data.rootCauseList.find((item) => item.issueId === selectedRootCause?.issueId)
        if (result) {
          setShowPendingConfirmedDSATs(!!result.enable)
        }
      }
    }, [rootCauseSnapshot, selectedRootCause])

    const table = React.useMemo(() => {
      const curTicketsSnapshot =
        currentTab === RootCauseTab.LinkedDSATs ? linkedTicketsSnapshot : recommendTicketsSnapshot
      const loadMoreFunction =
        currentTab === RootCauseTab.LinkedDSATs ? loadMoreLinkedTickets : loadMoreRecommendTickets
      const hasMore = currentTab === RootCauseTab.LinkedDSATs ? linkedTicketsHasMore : recommendTicketsHasMore

      if (curTicketsSnapshot.status === 'waiting') {
        return <TableLoading />
      }
      if (curTicketsSnapshot.status === 'error') {
        return <ErrorView error={curTicketsSnapshot.error} />
      }
      if (curTicketsSnapshot.status === 'done') {
        return curTicketsSnapshot.data.ticketCount > 0 ? (
          <AGGridTable
            key={currentTab}
            className={styles.container}
            rowData={curTicketsSnapshot.data.tickets}
            columnDefs={colDefs}
            getRowId={(data) => data.data.ticketId}
            onRowClicked={onRowClick}
            hasMore={hasMore}
            loadMore={loadMoreFunction}
            focusedRowId={clickedTicketKey}
            domLayout={curTicketsSnapshot.data.ticketCount > 30 ? 'normal' : 'autoHeight'}
            rowSelectionMode="multiRow"
            onSelectionChange={handleSelectionChange}
          />
        ) : (
          <ErrorView.Custom className={styles.flexGrow} level="WARNING" message="No tickets found for this query" />
        )
      }
      return null
    }, [
      currentTab,
      linkedTicketsSnapshot,
      recommendTicketsSnapshot,
      loadMoreLinkedTickets,
      loadMoreRecommendTickets,
      linkedTicketsHasMore,
      recommendTicketsHasMore,
      styles.container,
      styles.flexGrow,
      colDefs,
      onRowClick,
      clickedTicketKey,
      handleSelectionChange,
    ])

    const updateSelectedTab = (tab: React.SetStateAction<RootCauseTab>) => {
      setCurrentTab(tab)
    }

    const renderItemCount = (item: RootCauseTab) => {
      if (item === RootCauseTab.LinkedDSATs) {
        return typeof linkedDSATsCount === 'number' && ` (${linkedDSATsCount})`
      } else {
        return typeof recommendedDSATsCount === 'number' && ` (${recommendedDSATsCount})`
      }
    }

    const handleGoBack = React.useCallback(() => {
      const product = useTeamViewStore.getState().productName
      const lastSelectedTeam = useTeamViewStore.getState().teams.lastSelectedTeam
      lastSelectedTeam &&
        product &&
        TeamRoute.navigator.navigate({
          product,
          subMenu: TeamViewSubMenuIds.RootCauses,
          teamName: lastSelectedTeam,
          channel: DefaultProductChannelMapping[product],
        })
    }, [])

    React.useEffect(() => {
      return () => {
        useTeamViewStore.getState().toggleFilterPanel(false)
      }
    }, [])

    React.useEffect(() => {
      if (selectedRootCause?.eTA) {
        const eTADate = new Date(selectedRootCause.eTA)
        const targetDate = new Date(selectedRootCause.eTA)
        targetDate.setDate(eTADate.getDate() + 10)

        const timer = setInterval(() => {
          const now = new Date()
          const timeDifference = targetDate.getTime() - now.getTime()
          const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
          if (daysLeft < 0) {
            clearInterval(timer)
          }
        }, 60000)
        return () => clearInterval(timer)
      } else {
        return
      }
    }, [selectedRootCause])

    return (
      <>
        <Row className={styles.header} wrap>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbButton
                size="large"
                onClick={handleGoBack}
                style={{ height: 'unset', minWidth: 80, padding: 0 }}
              >
                <Text wrap size={300} className={styles.breakWord}>
                  Root Causes
                </Text>
              </BreadcrumbButton>
            </BreadcrumbItem>
            <BreadcrumbDivider />
          </Breadcrumb>
        </Row>
        {rootCauseSnapshot?.status === 'waiting' ? (
          <Column>
            <Spacer height={8} />
            <Skeleton aria-label="Loading Content">
              <SkeletonItem />
            </Skeleton>
            <Spacer height={8} />
            <Skeleton aria-label="Loading Content">
              <SkeletonItem />
            </Skeleton>
            <Spacer height={8} />
          </Column>
        ) : (
          <Column>
            <Spacer height={8} />
            <Row>
              <Text wrap size={600} weight="semibold" className={styles.rootCauseTitle}>
                {selectedRootCause?.rootCauseTitle}
              </Text>
              <Spacer className={styles.minGap} />
              <Button
                className={styles.editBtn}
                appearance="transparent"
                icon={<EditRegular />}
                onClick={() => setDialogOpen(true)}
              >
                Edit Root Cause
              </Button>
            </Row>
            <Spacer height={8} />
            <Row className={styles.subInfo}>
              <Row>
                <Text size={300}>Assigned to:&nbsp;</Text>
                {selectedRootCause?.owner ? (
                  <Text>
                    <UserProfile
                      userId={selectedRootCause.owner}
                      fallback={selectedRootCause.owner}
                      customUserPhotoClassName={styles.customUserPhoto}
                      customUserDisplayNameClassName={styles.customUserName}
                    />
                  </Text>
                ) : (
                  <Text className={styles.empty}>Not Assigned</Text>
                )}
              </Row>
              <Spacer style={{ flex: '0 1 40px' }} />
              <Row>
                <Text size={300}>Fix by:&nbsp;</Text>

                {selectedRootCause?.eTA ? (
                  <Text size={300} weight="semibold">
                    <TimeView value={selectedRootCause?.eTA} format="YYYY/MM/DD" />
                  </Text>
                ) : (
                  <Text className={styles.empty}>Not Selected</Text>
                )}
              </Row>
              <Spacer style={{ flex: '0 1 40px' }} />
              <Row>
                <Text size={300}>Priority:&nbsp;</Text>
                <Text size={300} weight="semibold">
                  P{selectedRootCause?.rootCausePriority}
                </Text>
              </Row>
              <Spacer width={40} />
              <Row>
                <Text size={300}>State:&nbsp;</Text>
                <Text size={300} weight="semibold">
                  {selectedRootCause?.rootCauseStatus}
                </Text>
              </Row>
            </Row>
          </Column>
        )}
        <QuickSearch />
        <OverflowTabList
          items={showPendingConfirmedDSATs ? DefaultRootCauseTab : LinkedDSATsTab}
          renderItem={(item) => (
            <>
              {item}
              {renderItemCount(item)}
            </>
          )}
          onSelect={updateSelectedTab}
        />
        <Spacer height={16} />
        {table}
        <RootCauseCreateDialog
          fields={[FieldNames.PRIORITY, FieldNames.TITLE, FieldNames.STATE, FieldNames.DUEDATE, FieldNames.ASSIGNEDTO]}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onOk={handleUpdateRootCause}
          okBtnText="Save"
          defaultValue={{
            [FieldNames.PRIORITY]: selectedRootCause?.rootCausePriority as 1 | 2 | 3 | 4 | undefined,
            [FieldNames.TITLE]: selectedRootCause?.rootCauseTitle,
            [FieldNames.STATE]: selectedRootCause?.rootCauseStatus,
            [FieldNames.DUEDATE]: selectedRootCause?.eTA,
            [FieldNames.ASSIGNEDTO]: selectedRootCause?.owner,
          }}
          title="Edit root cause"
        />
      </>
    )
  },
)

TicketsTableForRootCause.displayName = 'TicketsTableForRootCause'
