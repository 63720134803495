import { IProductData, IProductEndpointData } from '../IProductData'

import { DefaultProductChannels } from './DefaultProductChannels'
import { DefaultProductEndpoints } from './DefaultProductEndpoints'
import { ProductIds } from './ProductIds'
import { ProductName } from '../ProductName'

export const DefaultProducts: IProductData[] = [
  {
    name: 'M365Chat',
    title: 'BizChat (Work)',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.M365Chat),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.M365Chat),
    productId: ProductIds.M365Chat,
  },
  {
    name: 'M365ChatWebChat',
    title: 'BizChat (Web)',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.M365ChatWebChat),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.M365ChatWebChat),
    productId: ProductIds.M365ChatWebChat,
  },
  {
    name: 'ExcelCopilot',
    title: 'Excel Copilot',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.ExcelCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.ExcelCopilot),
    productId: ProductIds.ExcelCopilot,
  },
  {
    name: 'WordCopilot',
    title: 'Word Copilot ',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.WordCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.WordCopilot),
    productId: ProductIds.WordCopilot,
  },
  {
    name: 'PPTCopilot',
    title: 'PowerPoint Copilot',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.PPTCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.PPTCopilot),
    productId: ProductIds.PPTCopilot,
  },
  {
    name: 'TeamsMeetingCopilot',
    title: 'Teams Meeting Copilot',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.TeamsMeetingCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.TeamsMeetingCopilot),
    productId: ProductIds.TeamsMeetingCopilot,
  },
  {
    name: 'ODSPCopilot',
    title: 'ODSP Copilot',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.ODSPCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.ODSPCopilot),
    productId: ProductIds.ODSPCopilot,
  },
  {
    name: 'LoopCopilot',
    title: 'Loop Copilot',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.LoopCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.LoopCopilot),
    productId: ProductIds.LoopCopilot,
  },
  {
    name: 'OneNoteCopilot',
    title: 'OneNote Copilot',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.OneNoteCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.OneNoteCopilot),
    productId: ProductIds.OneNoteCopilot,
  },
  {
    name: 'OutlookCopilot',
    title: 'Outlook Copilot',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.OutlookCopilot),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.OutlookCopilot),
    productId: ProductIds.OutlookCopilot,
  },
  {
    name: 'TenantAdminFeedback',
    title: 'Tenant Admin Feedback',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.TenantAdminFeedback),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.TenantAdminFeedback),
    productId: ProductIds.TenantAdminFeedback,
  },
  {
    name: 'USERP',
    title: 'USERP',
    endpoints: DefaultProductEndpoints.filter((endpoint) => endpoint.productId === ProductIds.USERP),
    channels: DefaultProductChannels.filter((channel) => channel.productId === ProductIds.USERP),
    productId: ProductIds.USERP,
  },
]

export function getProductIdByName(name: string | undefined | null) {
  if (name === undefined || name === null) return undefined
  return DefaultProducts.find((product) => product.name === name)?.productId
}
export function getProductNameById(id: number | undefined | null) {
  if (id === undefined || id === null) return undefined
  return DefaultProducts.find((product) => product.productId === id)?.name
}
export function getProductIdByEndpoint(endpoint: string | undefined | null) {
  if (endpoint === undefined || endpoint === null) return undefined
  const productId = DefaultProductEndpoints.find(
    (item) => item.name.toLocaleLowerCase() === endpoint.toLocaleLowerCase(),
  )?.productId
  return productId
}
export function getProductTitleById(id: number) {
  return DefaultProducts.find((product) => product.productId === id)?.title
}

export function getProductEndpoints(
  product: ProductName | undefined,
  applicationArr: string[] | undefined,
  platformArr: string[] | undefined,
  licenseArr: string[] | undefined,
  meetingScenarioArr: string[] | undefined,
): string[] {
  let productEndPoints = DefaultProducts.find((item) => item.productId === getProductIdByName(product))?.endpoints || []

  const filters = [
    { propertyList: applicationArr, propertyName: 'application' as keyof IProductEndpointData },
    { propertyList: platformArr, propertyName: 'platform' as keyof IProductEndpointData },
    { propertyList: licenseArr, propertyName: 'license' as keyof IProductEndpointData },
    { propertyList: meetingScenarioArr, propertyName: 'meetingScenario' as keyof IProductEndpointData },
  ]

  for (const filter of filters) {
    if (productEndPoints.length > 0 && filter.propertyList?.length) {
      productEndPoints = getEndpointsByProperty(productEndPoints, filter.propertyList, filter.propertyName)
    }
  }

  return productEndPoints.map((endpoint) => endpoint.name)
}

function getEndpointsByProperty(
  endpoints: IProductEndpointData[],
  propertyList: string[],
  propertyName: keyof IProductEndpointData,
): IProductEndpointData[] {
  return endpoints.filter((endpoint) => {
    const propertyValue = endpoint[propertyName]

    if (propertyValue === undefined || propertyValue === null) {
      return false
    }

    return propertyList.includes(propertyValue.toString())
  })
}
