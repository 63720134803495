import {
  ApiOdsDiagnosticData,
  ApiOdsInteractionInfos,
  apiOdsInteractionInfosSchema,
  ApiOdsTicketData,
  ApiOdsTicketVerbatim,
  parseBase64GzipStringTo,
} from '@copilot-dash/api'
import { ITicketSessionData, ITicketTurnDiagnosticData, ITicketTurnMetadata } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { compact, sortBy } from 'lodash'
import { z } from 'zod'

export function convertTicketSessionData(ticketId: string, root: ApiOdsTicketData): ITicketSessionData {
  return {
    feedbackTime: root['Feedback Date'],
    verbatim: getVerbatim(root),
    turns: getInteractions(root),
    ring: root.Ring,
    isConverged: root.IsConverged === 'True',
    raw: root,
  }

  function getVerbatim(api: ApiOdsTicketData): string | undefined {
    return (
      getVerbatimFromStringOrObject(api['Detailed Feedback Verbatim']) ||
      getVerbatimFromStringOrObject(api['General Feedback Verbatim']) ||
      getVerbatimFromStringOrObject(api.Verbatim) ||
      getVerbatimFromStringOrObject(api.ProblemStatement) ||
      getVerbatimFromStringOrObject(api.ProblemStatementInvariant)
    )
  }

  function getVerbatimFromStringOrObject(verbatim?: string | ApiOdsTicketVerbatim): string | undefined {
    if (!verbatim) {
      return undefined
    }

    if (typeof verbatim === 'string') {
      return verbatim
    }

    if (Array.isArray(verbatim)) {
      return verbatim.join(', ')
    }

    return undefined
  }

  function getInteractions(api: ApiOdsTicketData): ITicketTurnMetadata[] {
    let apiInteractions: ApiOdsInteractionInfos[] | undefined

    // Case 1: InteractionInfoGZip is present
    if (api.InteractionInfoGZip) {
      apiInteractions = parseBase64GzipStringTo(api.InteractionInfoGZip, z.array(apiOdsInteractionInfosSchema))
    }

    // Case 2: InteractionInfos is present
    if (!apiInteractions) {
      apiInteractions = api.InteractionInfos
    }

    // Case 3: Non converged ticket
    // This is a fallback for non converged tickets before June 2024
    if (!apiInteractions) {
      const ConversationId = z.string().safeParse(api.ConvId).data
      const InteractionTimeStr = z.string().safeParse(api.UtteranceHappenTime).data
      const TransactionIds = z.string().array().safeParse(api.TransactionIds).data ?? []
      const ImpressionIdsString = z.string().safeParse(api.ImpressionIds).data
      const ImpressionIds = ImpressionIdsString?.split(',')
        .map((item) => item.trim())
        .filter((item) => item)

      apiInteractions = [
        {
          ConversationId,
          MessageId: api.TraceId,
          InteractionTimeStr,
          Index: 1,
          ClientName: api.ClientName ?? api['Client Name'],
          SubstrateSearchReplayInfoList: TransactionIds.map((transactionId) => ({
            TransactionId: transactionId,
          })),
          ImpressionIds,
        },
      ]
    }

    return sortBy(
      apiInteractions?.map((item) => getInteraction(item)),
      (item) => {
        return -item.index
      },
    )
  }

  function getInteraction(api: ApiOdsInteractionInfos): ITicketTurnMetadata {
    if (!api.MessageId) {
      const statusCode = api.Diagnostic?.Conversation?.StatusCode
      if (statusCode) {
        const error = TicketError.diagnostic(statusCode, ticketId)
        if (error) {
          throw error
        }
      }

      throw TicketError.create('NoConversationDueToMessageIdNotFound', { ticketId })
    }

    return {
      messageId: api.MessageId,
      time: api.InteractionTimeStr,
      index: api.Index,
      diagnostic: api.Diagnostic ? getDiagnostic(api.Diagnostic) : undefined,
      offlineTransactionIds: api.ImpressionIds ?? [],
      onlineTransactionIds: compact(api.SubstrateSearchReplayInfoList?.map((item) => item.TransactionId)),
      // `1` means the last turn
      isLastTurn: api.Index === 1,
      raw: api,
    }
  }

  function getDiagnostic(api: ApiOdsDiagnosticData): ITicketTurnDiagnosticData {
    return {
      conversation: {
        statusCode: api.Conversation.StatusCode,
      },
      substrateSearch: (api.SubstrateSearch ?? []).map((item) => ({
        statusCode: item.StatusCode,
      })),
      offline: (api.Offline ?? []).map((item) => ({
        statusCode: item.StatusCode,
      })),
    }
  }
}
