import { makeStyles, tokens } from '@fluentui/react-components'

import { Scrollbar } from '../../../../components/Scrollbar/Scrollbar'

/**
 * We use an empty space to display when the message content is empty to prevent the message content from collapsing.
 */
const EMPTY_PLACEHOLDER = `&nbsp;`

interface IProps {
  readonly text?: string
  readonly markdownText?: string
  readonly adaptiveCard?: object
}

export function UserMessageContext({ text, markdownText, adaptiveCard }: IProps) {
  const styles = useStyles()

  return (
    <Scrollbar className={styles.root}>
      {(() => {
        // todo result list display
        return <div className={styles.text}>{text || EMPTY_PLACEHOLDER}</div>
      })()}
    </Scrollbar>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px 16px',
    borderRadius: '8px 8px 2px',
    backgroundColor: tokens.colorBrandBackgroundInvertedSelected,
  },
  markdown: {
    color: tokens.colorNeutralForeground1,
    wordBreak: 'break-word',

    '&> p': {
      marginTop: '12px',
      marginBottom: '12px',
    },

    '&> pre': {
      whiteSpace: 'pre-wrap',
    },
  },
  text: {
    color: tokens.colorNeutralForeground1,
    wordBreak: 'break-word',
    padding: '6px 0',
  },
})
