import { z } from 'zod'
import { Caches } from '../../client/ApiClientCaches'
import { DashApiOptions } from '../../DashApiOptions'
import { assertNotEmpty } from '../../utils/assertNotEmpty'
import { OdsApiClient } from './OdsApiClient'
import {
  ApiConversation,
  ApiConversationGroup1,
  apiConversationGroup1Schema,
  ApiConversationGroup2,
  apiConversationGroup2Schema,
  ApiConversationGroup3,
  apiConversationGroup3Schema,
  ApiConversationGroup4,
  apiConversationGroup4Schema,
  ApiOds3sOfflineDataGroup1,
  ApiOds3sOfflineDataGroup2,
  apiOds3sOfflineDataGroup2Schema,
  ApiOds3sOfflineDataGroup3,
  apiOds3sOfflineDataGroup3Schema,
  ApiOds3sOfflineDataGroup4,
  apiOds3sOfflineDataGroup4Schema,
  ApiOds3sOnlineDataGroup1,
  apiOds3sOnlineDataGroup1Schema,
  ApiOds3sOnlineDataGroup2,
  apiOds3sOnlineDataGroup2Schema,
  ApiOds3sOnlineDataGroup3,
  apiOds3sOnlineDataGroup3Schema,
  ApiOds3sOnlineDataGroup4,
  apiOds3sOnlineDataGroup4Schema,
  ApiOdsFile,
  ApiOdsSearchRequest,
  ApiOdsTicketData,
  apiOdsTicketDataSchema,
} from './types'
import { apiConversationSchema } from './types/ApiConversation.schema'
import { apiOds3sOfflineDataGroup1Schema } from './types/ApiOds3sOfflineDataGroup1.schema'
import { apiOdsFileSchema } from './types/ApiOdsFile.schema'

export class OdsApi {
  private readonly client: OdsApiClient

  constructor(options: DashApiOptions) {
    this.client = new OdsApiClient(options)
  }

  getMetadata(sessionId: string): Promise<ApiOdsTicketData> {
    assertNotEmpty(sessionId, 'Parameter "sessionId" should not be empty. Please check!')

    return this.client.get(`/v1/datacollectorresult`, {
      params: {
        sessionId: sessionId,
      },
      schema: apiOdsTicketDataSchema,
      cache: Caches.SHORT,
    })
  }

  getFiles(ticketId: string): Promise<Array<ApiOdsFile>> {
    const encodedId = encodeURIComponent(ticketId)

    return this.client.get(`/v1/files/${encodedId}`, {
      schema: z.array(apiOdsFileSchema),
      cache: Caches.SHORT,
    })
  }

  getConversation(url: string): Promise<ApiConversation> {
    return this.client.get(url, {
      schema: apiConversationSchema,
      cache: Caches.SHORT,
    })
  }

  getConversationGroup1(url: string): Promise<ApiConversationGroup1> {
    return this.client.get(url, {
      schema: apiConversationGroup1Schema,
      cache: Caches.LONG,
    })
  }

  getConversationGroup2(url: string): Promise<ApiConversationGroup2> {
    return this.client.get(url, {
      schema: apiConversationGroup2Schema,
      cache: Caches.LONG,
    })
  }

  getConversationGroup3(url: string): Promise<ApiConversationGroup3> {
    return this.client.get(url, {
      schema: apiConversationGroup3Schema,
      cache: Caches.LONG,
    })
  }

  getConversationGroup4(url: string): Promise<ApiConversationGroup4> {
    return this.client.get(url, {
      schema: apiConversationGroup4Schema,
      cache: Caches.LONG,
    })
  }

  get3sOnlineDataGroup1(fileUrl: string): Promise<ApiOds3sOnlineDataGroup1> {
    return this.client.get(fileUrl, {
      schema: apiOds3sOnlineDataGroup1Schema,
      cache: Caches.LONG,
    })
  }

  get3sOnlineDataGroup2(fileUrl: string): Promise<ApiOds3sOnlineDataGroup2> {
    return this.client.get(fileUrl, {
      schema: apiOds3sOnlineDataGroup2Schema,
      cache: Caches.LONG,
    })
  }
  get3sOnlineDataGroup3(fileUrl: string): Promise<ApiOds3sOnlineDataGroup3[]> {
    return this.client.get(fileUrl, {
      schema: z.array(apiOds3sOnlineDataGroup3Schema),
      cache: Caches.LONG,
    })
  }

  get3sOnlineDataGroup4(fileUrl: string): Promise<ApiOds3sOnlineDataGroup4> {
    return this.client.get(fileUrl, {
      schema: apiOds3sOnlineDataGroup4Schema,
      cache: Caches.LONG,
    })
  }

  get3sOfflineDataGroup1(fileUrl: string): Promise<ApiOds3sOfflineDataGroup1> {
    return this.client.get(fileUrl, {
      schema: apiOds3sOfflineDataGroup1Schema,
      cache: Caches.LONG,
    })
  }

  get3sOfflineDataGroup2(fileUrl: string): Promise<ApiOds3sOfflineDataGroup2> {
    return this.client.get(fileUrl, {
      schema: apiOds3sOfflineDataGroup2Schema,
      cache: Caches.LONG,
    })
  }
  get3sOfflineDataGroup3(fileUrl: string): Promise<ApiOds3sOfflineDataGroup3> {
    return this.client.get(fileUrl, {
      schema: apiOds3sOfflineDataGroup3Schema,
      cache: Caches.LONG,
    })
  }

  get3sOfflineDataGroup4(fileUrl: string): Promise<ApiOds3sOfflineDataGroup4> {
    return this.client.get(fileUrl, {
      schema: apiOds3sOfflineDataGroup4Schema,
      cache: Caches.LONG,
    })
  }

  search(data: ApiOdsSearchRequest): Promise<string[]> {
    return this.client.post('/v2/file/search', {
      data: data,
      schema: z.array(z.string()),
      cache: Caches.SHORT,
    })
  }
}
