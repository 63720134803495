import { UN_TRIAGED_TEAM_IDS } from '@copilot-dash/domain'
import { isNil } from 'lodash'
import { COPILOT_DASH_STATE_OPTIONS } from '../TicketSummaryPanel/children/utils'
import { z } from 'zod'

export const summarySatisfyPost = z.object({
  workItemId: z.string(),
  ticketId: z.string(),
  status: z.enum(['New', 'Active', 'Resolved', 'Closed', '']),
  priority: z.number(),
  areaPath: z.string().optional(),
  teamId: z.number(),
  assignTo: z.string().optional(),
  issueList: z.array(
    z.object({
      issueId: z.string(),
      title: z.string(),
      vsoAccount: z.string(),
      visible: z.boolean(),
      rootCauseStatus: z.string(),
    }),
  ),
  customTags: z.array(z.string()).optional(),
  reasoning: z.string(),
  closedComment: z.string(),
})

export const EMPTY_ROOT_CAUSE = ''
export function getRootCauseSelectOptionIds(
  rootCauseList:
    | Array<{
        readonly issueId: string
        readonly rootCauseTitle?: string
      }>
    | null
    | undefined,
) {
  if (isNil(rootCauseList)) return [EMPTY_ROOT_CAUSE]
  if (rootCauseList.length === 0) return [EMPTY_ROOT_CAUSE]
  return rootCauseList.map((item) => item.issueId)
}

export const TICKET_STEP_STAGES = ['Untriaged', 'Team Assigned', 'Root Caused', 'Closed'] as const
export const TICKET_STEP_STAGES_WITHOUT_ROOT_CAUSE = ['Untriaged', 'Team Assigned', 'Closed'] as const

export function getTicketStepStageByFields(
  status: (typeof COPILOT_DASH_STATE_OPTIONS)[number] | undefined,
  rootCauseList: unknown[] | undefined,
  teamId: number | undefined,
): (typeof TICKET_STEP_STAGES)[number] {
  if (status === 'Active') {
    if (!teamId || (teamId && UN_TRIAGED_TEAM_IDS.includes(teamId))) return 'Untriaged'
    if (rootCauseList?.length) {
      return 'Root Caused'
    } else {
      return 'Team Assigned'
    }
  } else {
    return 'Closed'
  }
}

export function getCurrentStepIndex<T extends boolean>(
  currentStep: T extends true
    ? (typeof TICKET_STEP_STAGES_WITHOUT_ROOT_CAUSE)[number]
    : (typeof TICKET_STEP_STAGES)[number],
  withoutRootCause: T,
): number {
  return withoutRootCause
    ? TICKET_STEP_STAGES_WITHOUT_ROOT_CAUSE.indexOf(
        currentStep as (typeof TICKET_STEP_STAGES_WITHOUT_ROOT_CAUSE)[number],
      )
    : TICKET_STEP_STAGES.indexOf(currentStep)
}
