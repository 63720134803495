import { makeStyles } from '@fluentui/react-components'

export const TICKET_SUMMARY_PANEL_WIDTH = 1180

export const useStyles = makeStyles({
  card: {
    width: `${TICKET_SUMMARY_PANEL_WIDTH}px`,
    height: '100%',
    paddingRight: '20px',
  },

  drawerHeader: {
    width: '100%',
  },
  headerTitle: {
    margin: '0 0 8px 0',
    padding: '0px 15px',
  },
})
