import { motion } from 'framer-motion'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenActionContent } from './TicketScreenActionContent'

export function TicketScreenAction() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketSnapshot = app.store.use.getRawV1Ticket(ticketId)

  const enableEdit = ticketSnapshot.data?.vsoLink || ticketSnapshot.data?.workItemId
  if (!enableEdit) {
    return null
  }

  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={{ height: 'auto', opacity: 1 }}
      style={{ overflow: 'hidden' }}
    >
      <TicketScreenActionContent />
    </motion.div>
  )
}
