import { z } from 'zod'

export const apiV2TicketSourceSchema = z.object({
  relatedId: z.string().optional(),
  relatedType: z.string().optional(),
  type: z.string(),
  complianceLevel: z.number(),
  name: z.string().optional(),
  url: z.string(),
})

export const apiV2TicketSourceTypeSchema = z.union([
  z.literal('OCV'),
  z.literal('Conversation'),
  z.literal('SubstrateSearchOnline'),
  z.literal('SubstrateSearchOffline'),
  z.literal('SubstrateSearchInfo'),
  z.literal('SubstrateSearchException'),
  z.literal('SydneyTuringBotTraceMDS'),
  z.literal('BizchatPerformanceEventV4'),
  z.literal('SydneyTuringBotChatMessageEventMDS'),
  z.literal('SydneyTuringBotMonitoredScopeAgentType'),
  z.literal('SydneyTuringBotMonitoredScope'),
  z.literal('SydneyTuringBotEventMDS'),
  z.literal('LLM'),
  z.literal('BizChat3SLatency'),
])

export const apiV2TicketSourceComplianceLevelSchema = z.union([
  z.literal(0),
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.literal(4),
])
