import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketMetadata, TicketEmotionType } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV1Ticket } from '../actions-raw-ticket/getRawV1Ticket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'

export function getTicketMetadata(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketMetadata> {
  return context.getOrFetch<ITicketMetadata>({
    get: (state) => {
      return state.tickets[ticketId]?.metadata
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.metadata = snapshot
    },
    fetch: async () => {
      return context.enableV2Endpoint ? fetchFromV2() : fetchFromV1()
    },
  })

  async function fetchFromV1(): Promise<ITicketMetadata> {
    const raw = await getRawV1Ticket(context, ticketId).promise

    return {
      id: raw.ticketId,
      createdDateTime: raw.dateTimeUtc ?? '',
      emotionType: raw.thumbFeedback === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
      ocvLink: raw.oCVLink,
      tenantId: raw.tenantId,
      tenantName: raw.tenantName,
    }
  }

  async function fetchFromV2(): Promise<ITicketMetadata> {
    const raw = await getRawV2Ticket(context, ticketId).promise

    return {
      id: raw.id,
      createdDateTime: raw.userFeedback.createdDateTime,
      emotionType: raw.userFeedback.sentiment === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
      ocvLink: raw.userFeedback.ocvLink,
      tenantId: raw.user?.tenantId,
      tenantName: undefined,
    }
  }
}
