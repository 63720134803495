import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketContextData } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawV1Ticket } from '../actions-raw-ticket/getRawV1Ticket'
import { getRawV2Ticket } from '../actions-raw-ticket/getRawV2Ticket'
import { getTicketEmail } from './getTicketEmail'

export function getTicketContext(context: IDashStoreContext, ticketId: string): PromiseSnapshot<object> {
  return context.getOrFetch<object>({
    get: (state) => {
      return state.tickets[ticketId]?.ticketContext
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.ticketContext = snapshot
    },
    fetch: async () => {
      return context.enableV2Endpoint ? fetchFromV2() : fetchFromV1()
    },
  })

  async function fetchFromV1(): Promise<ITicketContextData> {
    const ticketPromise = getRawV1Ticket(context, ticketId).promise
    const emailPromise = getTicketEmail(context, ticketId).promise

    const ticket = await ticketPromise
    const email = await emailPromise.catch((error) => String(error))

    return {
      email: email,
      dateTime: ticket.dateTimeUtc,

      clientName: ticket.clientName,
      scenarioName: ticket.scenarioName,
      uiHost: ticket.uiHost,
      ring: ticket.ring,

      ocvLink: ticket.oCVLink,
      ocvLanguage: ticket.oCVLanguage,

      systemTags: ticket.categories,

      adoLink: ticket.vsoLink,
      adoDescription: ticket.vsoDescription,
      adoAccount: ticket.vsoAccount,
    }
  }

  async function fetchFromV2(): Promise<object> {
    return getRawV2Ticket(context, ticketId).promise
  }
}
