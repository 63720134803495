import {
  AllSupportedAIFColumns,
  AllSupportedNonAIFColumns,
  AlSearchEnabledColumns,
  getProductIdByName,
  INewTicketData,
  IUpdateTicketStatusInfoData,
  NonRemovableColumns,
  overViewTabsSchema,
  SearchTextPrefixType,
} from '@copilot-dash/domain'
import { SelectTabData, SelectTabEvent } from '@fluentui/react-components'
import { motion } from 'framer-motion'
import { compact } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { COLUMN_SETTING_PANEL_WIDTH } from '../../../components/ColumnSettingPanel/children/ColumnSettingPanelBody.styles'
import { ColumnSettingPanel } from '../../../components/ColumnSettingPanel/ColumnSettingPanel'
import { Body, Column, Right, Row, Spacer } from '../../../components/Layout'
import { ITicketFilterFormRef } from '../../../components/TicketsFilterPanel/children/TicketFilterForm/TicketFilterForm.types'
import { FILTER_PANEL_WIDTH } from '../../../components/TicketsFilterPanel/layout/TicketsFilterPanelLayout.styles'
import { TICKET_SUMMARY_PANEL_WIDTH } from '../../../components/TicketSummaryPanel/layout/TicketSummaryPanelLayout.styles'
import { TicketSummaryPanel } from '../../../components/TicketSummaryPanel/TicketSummaryPanel'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { useToast } from '../../../hooks/useToast'
import { SearchRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { IssueDetail } from '../children/feedbackInsights/issueDetail'
import { FilterPanel } from '../children/panel/FilterPanel'
import { SearchScreenBody } from '../children/SearchScreenBody'
import { useSearchScreenActions, useSearchScreenState } from '../store'
import { useStyles } from './SearchScreenLayout.styles'
import { ColDef } from 'ag-grid-community'

export const SearchScreenLayout = memo(function SearchScreenLayout() {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const state = useSearchScreenState((state) => state)
  const form = state.form
  const columnsNonAIF = app.settings.searchScreenNonAIFColumns.use()
  const setColumnsNonAIF = app.settings.searchScreenNonAIFColumns.set

  const columnsAIF = app.settings.searchScreenAIFColumns.use()
  const setColumnsAIF = app.settings.searchScreenAIFColumns.set

  const columnsVipDSATs = app.settings.feedbackInsightsVipDSATsColumns.use()
  const setColumnsVipDSATs = app.settings.feedbackInsightsVipDSATsColumns.set

  const columnsTopIssueDetails = app.settings.feedbackInsightsTopIssueDetailsColumns.use()
  const setColumnsTopIssueDetails = app.settings.feedbackInsightsTopIssueDetailsColumns.set

  const formRef = useRef<ITicketFilterFormRef | null>(null)
  const [selectedColumns, setSelectedColumns] = useState<ColDef<INewTicketData>[]>([])
  const focusedTab = useSearchScreenState((state) => state.focusedTab)
  const issueId = SearchRoute.navigator.useArgsOptional()?.issueId
  const issueBatchId = SearchRoute.navigator.useArgsOptional()?.issueBatchId
  const isAISearchEnable = app.features.aiSearch.use()

  //Hide Team/Assign To/State/Priority/RootCause Column in All feedback when AI search enable From Local Storage
  const newColumnsNonAIF = useMemo(() => {
    if (isAISearchEnable) {
      return columnsNonAIF.filter((x) => AlSearchEnabledColumns.includes(x))
    }
    return columnsNonAIF
  }, [isAISearchEnable, columnsNonAIF])

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues({
        ...form,
        range: form.range ?? form.defaultRange,
      })
    }
  }, [form])

  const toast = useToast()

  const handleAfterSaved = useCallback(
    (updatedInfo: IUpdateTicketStatusInfoData, teamName?: string) => {
      actions.updateTicketStatus(updatedInfo.ticketId, { ...updatedInfo, teamDisplayName: teamName })
    },
    [actions],
  )

  const handleSave = useCallback(
    (draft: IUpdateTicketStatusInfoData) => {
      return useGlobalStore
        .getState()
        .postTicketDetails(draft)
        .then((resp) => {
          if (resp.updateStatus === true) {
            return
          } else {
            throw new Error(resp.errorMessage)
          }
        })
        .catch((err) => {
          toast.showError('Failed to save', err.message)
          throw err
        })
    },
    [toast],
  )

  // Update selected columns based on the form
  useEffect(() => {
    const defaultColumns = AllSearchTableColumns(form.searchTextPrefix || SearchTextPrefixType.All, form.searchText)
    if (form.product === 'TenantAdminFeedback') {
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        columnsAIF.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )
      setSelectedColumns(newSelectedColumns)
    } else {
      const newSelectedColumns: ColDef<INewTicketData>[] = compact(
        newColumnsNonAIF.map((columnId) => defaultColumns.find((column) => column.field === String(columnId))),
      )
      setSelectedColumns(newSelectedColumns)
    }
  }, [
    form.searchTextPrefix,
    form.searchText,
    form.product,
    columnsAIF,
    form.tenantIds,
    isAISearchEnable,
    newColumnsNonAIF,
  ])

  const onTabSelect = (_: SelectTabEvent, data: SelectTabData) => {
    const value = overViewTabsSchema.safeParse(data.value).data
    if (value) {
      actions.updateFiltersPanelVisibility(false)
      actions.updateColumnSettingPanelVisibility(false)
      actions.updateFocusedTab(value)
    }
  }

  return (
    <motion.div
      layout
      animate={
        focusedTab === 'allFeedback' && state.isTicketsFilterPanelOpen
          ? 'filterOpen'
          : state.isColumnSettingPanelOpen
            ? 'columnSettingOpen'
            : state.isTicketSummaryPanelOpen
              ? 'ticketSummaryOpen'
              : 'close'
      }
      className={styles.framerContainer}
    >
      <Row fill>
        <Spacer width="32px" />
        <Body className={styles.body}>
          <Column>
            <Body>
              {issueId && issueBatchId ? (
                <IssueDetail issueId={issueId} issueBatchId={issueBatchId} />
              ) : (
                <SearchScreenBody
                  selectedColumns={selectedColumns}
                  selectedValue={focusedTab}
                  onTabSelect={onTabSelect}
                />
              )}
            </Body>
          </Column>
        </Body>
        <Spacer width="12px" />
        <motion.div
          layout
          variants={{
            close: { width: 0 },
            filterOpen: { width: FILTER_PANEL_WIDTH },
            columnSettingOpen: { width: COLUMN_SETTING_PANEL_WIDTH },
            ticketSummaryOpen: { width: TICKET_SUMMARY_PANEL_WIDTH },
          }}
          className={styles.framerPanel}
          initial={false}
        >
          <Right className={styles.right}>
            <Column fill>
              <FilterPanel />
              <ColumnSettingPanel
                isPanelOpen={state.isColumnSettingPanelOpen}
                onDismiss={() => actions.updateColumnSettingPanelVisibility(false)}
                NonRemovableColumns={NonRemovableColumns}
                AllSupportedColumns={
                  form.product === 'TenantAdminFeedback'
                    ? AllSupportedAIFColumns
                    : isAISearchEnable
                      ? AlSearchEnabledColumns
                      : AllSupportedNonAIFColumns
                }
                selectedColumns={
                  focusedTab === 'feedbackInsights'
                    ? issueId
                      ? columnsTopIssueDetails
                      : columnsVipDSATs
                    : form.product === 'TenantAdminFeedback'
                      ? columnsAIF
                      : newColumnsNonAIF
                }
                setSelectedColumns={
                  focusedTab === 'feedbackInsights'
                    ? issueId
                      ? setColumnsTopIssueDetails
                      : setColumnsVipDSATs
                    : form.product === 'TenantAdminFeedback'
                      ? setColumnsAIF
                      : setColumnsNonAIF
                }
              />
              {state.clickedTicketInfo?.ticketId && (
                <TicketSummaryPanel
                  isTicketSummaryPanelOpen={state.isTicketSummaryPanelOpen}
                  onDismissTicketSummaryPanel={actions.onDismissTicketSummaryPanel}
                  ticketId={state.clickedTicketInfo.ticketId}
                  ticketInfo={state.clickedTicketInfo}
                  onSave={handleSave}
                  productId={getProductIdByName(form.product)}
                  afterSavedCallback={handleAfterSaved}
                />
              )}
            </Column>
          </Right>
        </motion.div>
      </Row>
    </motion.div>
  )
})
