import { AsyncSnapshots } from '@copilot-dash/core'
import { AnyData } from '../../../../../../../components/AnyData/AnyData'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'
import { TicketAsyncView } from '../../../../common/TicketAsyncView'
import { TicketFlameGraphButton } from '../../../../common/TicketFlameGraphButton'

export function BizChat3SLatencyView() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} selectedTurnId={selectedTurnId} />
}

function Content(props: { readonly ticketId: string; readonly selectedTurnId: string }) {
  const snapshot = app.store.use.get3SLatencyLogs(props.ticketId, props.selectedTurnId)

  return (
    <TicketAsyncView snapshot={snapshot}>
      {(data) => {
        const first = data[0]
        if (!first) {
          return null
        }

        return (
          <AnyData
            data={first}
            options={{
              toolbar: <TicketFlameGraphButton value={first.perfTraceFile} />,
            }}
          />
        )
      }}
    </TicketAsyncView>
  )
}
