import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tips: {
    color: tokens.colorNeutralStroke1Pressed,
  },
  fullWith: {
    width: '100%',
  },
  listBox: {
    maxHeight: '500px',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  addNew: {
    marginTop: '12px',
  },
  createAction: {
    padding: '6px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
    color: tokens.colorNeutralForeground2BrandHover,
  },

  groupHeader: {
    padding: ' 6px 10px',
    backgroundColor: tokens.colorNeutralBackground1,
    cursor: 'pointer',
  },
  optionText: {
    wordBreak: 'break-all',
    paddingLeft: '0px',
    '& .fui-Option__checkIcon': {
      width: 0,
    },
  },
  closedOptionText: {
    color: tokens.colorNeutralForeground4,
  },
  scrollbar: {
    maxHeight: '300px',
    padding: '6px',
  },
  tagStyle: {
    cursor: 'pointer',
    borderRadius: '4px',
    margin: '3px 8px 3px 0',
    '& .fui-Tag__primaryText': {
      overflow: 'hidden',
    },
  },
  dismissButton: {
    color: tokens.colorNeutralForeground3,
    height: tokens.lineHeightBase100,
  },
  textStyle: {
    maxWidth: '438px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})
