import { ApiOdsDiagnosticData, ApiOdsInteractionInfos } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketTurnDiagnosticData, ITicketTurnMetadata } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { compact } from 'lodash'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getRawOdsTicketInteractions } from '../actions-raw-ticket-ods/getRawOdsTicketInteractions'
import { assertTicketHasUserConsent } from '../actions-ticket-assert/assertTicketHasUserConsent'

export function getTicketTurnMetadata(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<ITicketTurnMetadata> {
  return context.getOrFetch<ITicketTurnMetadata>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.metadata
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.metadata = snapshot
    },
    fetch: async () => {
      return fetchFromOds()
    },
  })

  async function fetchFromOds(): Promise<ITicketTurnMetadata> {
    await assertTicketHasUserConsent(context, ticketId)

    const interactions = await getRawOdsTicketInteractions(context, ticketId).promise
    const interaction = interactions.find((i) => i.MessageId === messageId)
    if (interaction) {
      return convert(interaction)
    }

    // Check if the interaction message id is empty
    for (const item of interactions) {
      if (!item.MessageId) {
        const statusCode = item.Diagnostic?.Conversation?.StatusCode
        if (statusCode) {
          const error = TicketError.diagnostic(statusCode, ticketId)
          if (error) {
            throw error
          }
        }

        const onlineList = item.Diagnostic?.SubstrateSearch ?? []
        for (const online of onlineList) {
          const statusCode = online.StatusCode
          if (statusCode) {
            const error = TicketError.diagnostic(statusCode, ticketId)
            if (error) {
              throw error
            }
          }
        }
      }
    }

    // Throw error
    // TODO: It should throw a common error instead of a "conversation" error
    throw TicketError.create('NoConversationDueToMessageIdNotFound', { ticketId, ticketMessageId: messageId })
  }

  function convert(api: ApiOdsInteractionInfos): ITicketTurnMetadata {
    return {
      messageId: api.MessageId ?? messageId,
      time: api.InteractionTimeStr,
      index: api.Index,
      diagnostic: api.Diagnostic ? getDiagnostic(api.Diagnostic) : undefined,
      offlineTransactionIds: api.ImpressionIds ?? [],
      onlineTransactionIds: compact(api.SubstrateSearchReplayInfoList?.map((item) => item.TransactionId)),
      // `1` means the last turn
      isLastTurn: api.Index === 1,
      raw: api,
    }
  }

  function getDiagnostic(api: ApiOdsDiagnosticData): ITicketTurnDiagnosticData {
    return {
      conversation: {
        statusCode: api.Conversation.StatusCode,
      },
      substrateSearch: (api.SubstrateSearch ?? []).map((item) => ({
        statusCode: item.StatusCode,
      })),
      offline: (api.Offline ?? []).map((item) => ({
        statusCode: item.StatusCode,
      })),
    }
  }
}
