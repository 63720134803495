import { z, ZodType } from 'zod'
import { ApiClient } from '../../client/ApiClient'
import { Caches } from '../../client/ApiClientCaches'
import { DashApiOptions } from '../../DashApiOptions'
import { apiLlmKustoLogItemSchema } from '../LogCollector/types'
import {
  apiConversationGroup1Schema,
  apiConversationGroup2Schema,
  apiConversationGroup3Schema,
  apiConversationGroup4Schema,
  apiOds3sOfflineDataGroup2Schema,
  apiOds3sOfflineDataGroup3Schema,
  apiOds3sOfflineDataGroup4Schema,
  apiOds3sOnlineDataGroup1Schema,
  apiOds3sOnlineDataGroup2Schema,
  apiOds3sOnlineDataGroup3Schema,
  apiOds3sOnlineDataGroup4Schema,
} from '../ODS/types'
import { apiConversationSchema } from '../ODS/types/ApiConversation.schema'
import { apiOds3sOfflineDataGroup1Schema } from '../ODS/types/ApiOds3sOfflineDataGroup1.schema'
import { CopilotDashApiClient } from './CopilotDashApiClient'
import {
  ApiAISearchRequestBody,
  ApiAISearchRequestPrams,
  ApiAISearchResponse,
  apiAISearchResponseSchema,
  apiBizChat3SLatencyLogItemV2Schema,
  apiBizPerfLogItemV2Schema,
  apiExtensibilityLogItemV2Schema,
  apiGwsLogItemV2Schema,
  apiStateAndDurationKustoLogItemV2Schema,
  apiTraceKustoLogItemV2Schema,
  ApiV2SymptomReportResponse,
  ApiV2Ticket,
  apiV2TicketSchema,
} from './types'
import {
  ApiAttachmentRequest,
  ApiAttachmentResponse,
  ApiAttachmentResponseSchema,
  ApiAttachmentUrlResponse,
  arrayBufferSchema,
} from './types/ApiV2Attachment'
import {
  ApiCommentItemV2,
  apiCommentItemV2Schema,
  apiCommentListResponseV2Schema,
  ApiCommentsResponseV2,
} from './types/ApiV2CommentsResponse'
import { ApiDeleteCommentResponseV2, apiDeleteCommentResponseV2Schema } from './types/ApiV2DeleteCommentResponse'
import { apiV2SymptomReportResponseSchema } from './types/ApiV2SymptomReportResponse.schema'
import { ApiUpsertCommentRequestV2 } from './types/ApiV2UpsertCommentRequest'

/**
 * Code:
 * https://dev.azure.com/msasg/Falcon/_git/DSS?path=/services/LogCollector/src/Service/Api/LogCollector/V1/LogCollectorService.bond&_a=contents&version=GBmaster
 *
 * Swagger:
 * https://copilotdashwebservice-service.namcentral240175.copilot-dash-webservice.centralus-test.cosmic-int.office.net/swagger/index.html
 */
export class CopilotDashApi {
  private readonly client: ApiClient

  constructor(options: DashApiOptions) {
    this.client = new CopilotDashApiClient(options)
  }

  getTicket(ticketId: string): Promise<ApiV2Ticket> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}`, {
      schema: apiV2TicketSchema,
    })
  }

  getTicketSymptomReport(ticketId: string): Promise<ApiV2SymptomReportResponse> {
    const encodedTicketId = encodeURIComponent(ticketId)

    return this.client.get(`/api/v2/tickets/${encodedTicketId}/SymptomReports`, {
      schema: apiV2SymptomReportResponseSchema,
    })
  }

  getAISearchTicket(query: ApiAISearchRequestPrams, data: ApiAISearchRequestBody): Promise<ApiAISearchResponse> {
    return this.client.post(`/api/v2/ticket/search`, {
      params: query,
      data: data,
      schema: apiAISearchResponseSchema,
    })
  }

  getTicketBlob(ticketId: string, blobUrl: string) {
    const get = async <T>(schema: ZodType<T>): Promise<T> => {
      const encodedTicketId = encodeURIComponent(ticketId)
      return this.client.get(`/api/v2/tickets/${encodedTicketId}/blobFile`, {
        params: {
          blobUrl: blobUrl,
        },
        schema: schema,
      })
    }

    return {
      // Conversation
      asConversation: () => get(apiConversationSchema),
      asConversationGroup1: () => get(apiConversationGroup1Schema),
      asConversationGroup2: () => get(apiConversationGroup2Schema),
      asConversationGroup3: () => get(apiConversationGroup3Schema),
      asConversationGroup4: () => get(apiConversationGroup4Schema),

      // Search online
      asSubstrateSearchOnlineGroup1: () => get(apiOds3sOnlineDataGroup1Schema),
      asSubstrateSearchOnlineGroup2: () => get(apiOds3sOnlineDataGroup2Schema),
      asSubstrateSearchOnlineGroup3: () => get(z.array(apiOds3sOnlineDataGroup3Schema)),
      asSubstrateSearchOnlineGroup4: () => get(apiOds3sOnlineDataGroup4Schema),

      // Search offline
      asSubstrateSearchOfflineGroup1: () => get(apiOds3sOfflineDataGroup1Schema),
      asSubstrateSearchOfflineGroup2: () => get(apiOds3sOfflineDataGroup2Schema),
      asSubstrateSearchOfflineGroup3: () => get(apiOds3sOfflineDataGroup3Schema),
      asSubstrateSearchOfflineGroup4: () => get(apiOds3sOfflineDataGroup4Schema),

      // Kusto
      asSubstrateSearchLog: () => get(z.array(apiGwsLogItemV2Schema)),
      asSubstrateSearchLatencyLog: () => get(z.array(apiBizChat3SLatencyLogItemV2Schema)),
      asPerformanceLog: () => get(z.array(apiBizPerfLogItemV2Schema)),
      asLlmLog: () => get(z.array(apiLlmKustoLogItemSchema)),
      asStateDurationLog: () => get(z.array(apiStateAndDurationKustoLogItemV2Schema)),
      asTraceLog: () => get(z.array(apiTraceKustoLogItemV2Schema)),
      asExtensibility: () => get(z.array(apiExtensibilityLogItemV2Schema)),
    }
  }

  //Due to the current API only support Image/* types. that's why we named it.
  uploadImageV2(data: ApiAttachmentRequest): Promise<ApiAttachmentResponse> {
    const formData = new FormData()
    formData.append('file', data.file)
    return this.client.put(`/api/v2/discussions/attachments`, {
      data: formData,
      schema: ApiAttachmentResponseSchema,
      headers: {
        'Content-Type': data.file.type || 'application/octet-stream',
      },
    })
  }

  getAttachmentV2(rawUrl: string): Promise<ApiAttachmentUrlResponse> {
    const response = this.client.get('/api/v2/discussions/attachments', {
      params: { url: rawUrl },
      schema: arrayBufferSchema,
      cache: Caches.SHORT,
      responseType: 'arraybuffer',
    })
    return response
  }

  createComment(discussionId: string, data: ApiUpsertCommentRequestV2): Promise<ApiCommentItemV2> {
    return this.client.put(`/api/v2/discussions/${encodeURIComponent(discussionId)}/comments`, {
      schema: apiCommentItemV2Schema,
      data: data,
    })
  }

  deleteComment(discussionId: string, commentId: string): Promise<ApiDeleteCommentResponseV2> {
    return this.client.delete(
      `/api/v2/discussions/${encodeURIComponent(discussionId)}/comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiDeleteCommentResponseV2Schema,
      },
    )
  }

  editComment(id: string, commentId: string, data: ApiUpsertCommentRequestV2): Promise<ApiCommentItemV2> {
    return this.client.patch(
      `/api/v2/discussions/${encodeURIComponent(id)}/comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiCommentItemV2Schema,
        data: data,
      },
    )
  }

  getComments(discussionId: string): Promise<ApiCommentsResponseV2> {
    return this.client.get(`/api/v2/discussions/${encodeURIComponent(discussionId)}/comments`, {
      schema: apiCommentListResponseV2Schema,
    })
  }
}
