import { ApiCopilotTicketDetailContext } from '@copilot-dash/api'
import { ITicketData, TicketEmotionType, getProductIdByEndpoint } from '@copilot-dash/domain'
import { find } from 'lodash'
import { isAssignedTo3SDashTorus } from '../../../utils/is3SDashTorus'
import { TicketAdditionalContextConverter } from './TicketAdditionalContextConverter'

// Bot
const BOT_ENDPOINT_PREFIX = 'BotEndpoint:'
const BOT_ENDPOINT_AVALON1 = 'turingbot.sdf-master2.substrate-turing-turingbot.westus3-sdf.cosmic-ppe.office.net'
const BOT_ENDPOINT_AVALON2 = 'dev.cortana.ai'
const BOT_ENDPOINT_AVALON3 = 'Avalon'

export function convertTicketDataFromApiContext(api: ApiCopilotTicketDetailContext): ITicketData {
  const additionalContextObject = api.additionalContext
    ? TicketAdditionalContextConverter.fromApiString(api.additionalContext)
    : undefined
  const ticketId = api.externalTicketId
  return {
    ...api,
    additionalContextObject,
    isAvalon: isTicketAvalon(api),
    emotionType: api.thumbFeedback === 'Positive' ? TicketEmotionType.Positive : TicketEmotionType.Negative,
    productId: getProductIdByEndpoint(api.clientName),
    ticketId,
    assignTo: isAssignedTo3SDashTorus(api.assignTo) ? undefined : api.assignTo, // `3SDashTorus`, it's a robot, should be ignored
  }

  function isTicketAvalon(ticket: ApiCopilotTicketDetailContext): boolean {
    const botEndpoint = find(ticket.categories, (category) => category.startsWith(BOT_ENDPOINT_PREFIX))
    const botEndpointValue = botEndpoint?.split(':')[1]?.trim()
    return (
      botEndpointValue === BOT_ENDPOINT_AVALON1 ||
      botEndpointValue === BOT_ENDPOINT_AVALON2 ||
      botEndpointValue === BOT_ENDPOINT_AVALON3
    )
  }
}
