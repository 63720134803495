import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../IDashStoreContext'
import { getAttachment } from './actions-attachment/getAttachment'
import { uploadImage } from './actions-attachment/uploadImage'
import { getTenant } from './actions-basic-tenant/getTenant'
import { searchM365Tenants } from './actions-basic-tenant/searchM365Tenants'
import { getUserPhoto } from './actions-basic-user/getUserPhoto'
import { getUserProfile } from './actions-basic-user/getUserProfile'
import { getFilterTags } from './actions-basic/getFilterTags'
import { getProducts } from './actions-basic/getProducts'
import { createComment } from './actions-comment/createComment'
import { deleteComment } from './actions-comment/deleteComment'
import { getCommentCount } from './actions-comment/getCommentCount'
import { getComments } from './actions-comment/getComments'
import { updateComment } from './actions-comment/updateComment'
import { appendNewFCConversation } from './actions-feedback-copilot/appendNewFCConversation'
import { getFCConversationByUserId } from './actions-feedback-copilot/getFCConversationByUserId'
import { submitFCRagQuery } from './actions-feedback-copilot/submitFCRagQuery'
import { updateFCConversation } from './actions-feedback-copilot/updateFCConversation'
import { updateUserReadFCRagResult } from './actions-feedback-copilot/updateUserReadFCRagResult'
import { getMcpExperimentFeedback } from './actions-mcp/getMcpExperimentFeedback'
import { getRaw3sOfflineDataGroup1 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup1'
import { getRaw3sOfflineDataGroup2 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup2'
import { getRaw3sOfflineDataGroup3 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup3'
import { getRaw3sOfflineDataGroup4 } from './actions-raw-ticket-chat-3s/getRaw3sOfflineDataGroup4'
import { getRaw3sOnlineDataGroup1 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup1'
import { getRaw3sOnlineDataGroup2 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup2'
import { getRaw3sOnlineDataGroup3 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup3'
import { getRaw3sOnlineDataGroup4 } from './actions-raw-ticket-chat-3s/getRaw3sOnlineDataGroup4'
import { getRawConversation } from './actions-raw-ticket-chat/getRawConversation'
import { getRawConversationGroup1 } from './actions-raw-ticket-chat/getRawConversationGroup1'
import { getRawConversationGroup2 } from './actions-raw-ticket-chat/getRawConversationGroup2'
import { getRawConversationGroup3 } from './actions-raw-ticket-chat/getRawConversationGroup3'
import { getRawConversationGroup4 } from './actions-raw-ticket-chat/getRawConversationGroup4'
import { getRawOdsTicketInteractions } from './actions-raw-ticket-ods/getRawOdsTicketInteractions'
import { getRawOcvTicket } from './actions-raw-ticket/getRawOcvTicket'
import { getRawV1Ticket } from './actions-raw-ticket/getRawV1Ticket'
import { deleteQuery } from './actions-search-query/deleteQuery'
import { getMyQueries } from './actions-search-query/getMyQueries'
import { getQuery } from './actions-search-query/getQuery'
import { getTeamQueries } from './actions-search-query/getTeamQueries'
import { updateQuery } from './actions-search-query/updateQuery'
import { searchTickets } from './actions-search/searchTickets'
import { createRootCause } from './actions-team-root-cause/createRootCause'
import { getOrFetchRootCauseInfoByRootCauseId } from './actions-team-root-cause/getRootCauseInfoByRootCauseId'
import {
  appendRootCauseByTeam,
  getOrFetchRootCauseListByTeam,
  getRootCauseListByTeam,
  searchRootCauseByTicketCharacter,
  updateRootCauseByTeam,
} from './actions-team-root-cause/getRootCauseListByTeam'
import { getTeamRootCauses } from './actions-team-root-cause/getTeamRootCauses'
import { getTicketRootCause } from './actions-team-root-cause/getTicketRootCause'
import { updateRootCause } from './actions-team-root-cause/updateRootCause'
import { updateTicketRootCause } from './actions-team-root-cause/updateTicketRootCause'
import { getTopIssueExtendInfo } from './actions-team-top-issues/getTopIssueExtendInfo'
import { removeTicketFromTopIssue } from './actions-team-top-issues/removeTicketFromTopIssue'
import { batchUpdateTicketStatusInfo } from './actions-team/batchUpdateTicketStatusInfo'
import { getAssignedTeamMembers } from './actions-team/getAssignedTeamMembers'
import { getTeamAreasAndRootCauses } from './actions-team/getTeamAreasAndRootCauses'
import { getTeamDRIListByTeamId, setTeamDRIList } from './actions-team/getTeamDRIListByTeamId'
import {
  getOrFetchTeamIssueClusteringBatchInfoList,
  getTeamIssueClusteringBatchInfoList,
} from './actions-team/getTeamIssueClusteringBatchInfoList'
import {
  getOrFetchTeamIssueClusteringInfoList,
  getTeamIssueClusteringInfoList,
} from './actions-team/getTeamIssueClusteringInfoList'
import { getTeams } from './actions-team/getTeams'
import { updateTicketStatusInfo } from './actions-team/updateTicketStatusInfo'
import { getTicketActivityHistory } from './actions-ticket-activity-history/getTicketActivityHistory'
import { getTicketSource } from './actions-ticket-blob/getTicketSource'
import { getCallFlow } from './actions-ticket-call-flow/getCallFlow'
import { getTelemetryMetrics } from './actions-ticket-call-flow/getTelemetryMetrics'
import { get3sLogs } from './actions-ticket-chat-3s/get3sLogs'
import { get3sOfflineGeneralDiagnosticData } from './actions-ticket-chat-3s/get3sOfflineGeneralDiagnosticData'
import { get3sOnlineGeneralDiagnosticData } from './actions-ticket-chat-3s/get3sOnlineGeneralDiagnosticData'
import { getTicketChatTurnCitations } from './actions-ticket-chat/getTicketChatTurnCitations'
import { getTicketLastTurnMessageId } from './actions-ticket-chat/getTicketLastTurnMessageId'
import { getTicketMessageIds } from './actions-ticket-chat/getTicketMessageIds'
import { getTicketTurnConversation } from './actions-ticket-chat/getTicketTurnConversation'
import { getTicketTurnMetadata } from './actions-ticket-chat/getTicketTurnMetadata'
import { get3sGwsLogs } from './actions-ticket-kusto/get3sGwsLogs'
import { get3SLatencyLogs } from './actions-ticket-kusto/get3SLatencyLogs'
import { getExtensibilityLog } from './actions-ticket-kusto/getExtensibilityLog'
import { getLlmLogs } from './actions-ticket-kusto/getLlmLogs'
import { getPerformanceLogs } from './actions-ticket-kusto/getPerformanceLogs'
import { getStateDurationLog } from './actions-ticket-kusto/getStateDurationLog'
import { getTraceLogs } from './actions-ticket-kusto/getTraceLogs'
import { getSymptomReport } from './actions-ticket-symptom-report/getSymptomReport'
import { getTicketHealthData } from './actions-ticket-symptom-report/getTicketHealthData'
import { getAllCustomTags } from './actions-ticket-tags/getAllCustomTags'
import { getBatchTicketsCustomTags } from './actions-ticket-tags/getBatchTicketsCustomTags'
import { getTicketCustomTags } from './actions-ticket-tags/getTicketCustomTags'
import { updateAllCustomTags } from './actions-ticket-tags/updateAllCustomTags'
import { updateTicketCustomTag } from './actions-ticket-tags/updateTicketCustomTag'
import { getTicket } from './actions-ticket/getTicket'
import { getTicketContext } from './actions-ticket/getTicketContext'
import { getTicketEmail } from './actions-ticket/getTicketEmail'
import { getTicketEnvironment } from './actions-ticket/getTicketEnvironment'
import { getTicketIdByWorkItemId } from './actions-ticket/getTicketIdByWorkItemId'
import { getTicketMetadata } from './actions-ticket/getTicketMetadata'
import { getTicketResponseMarkdown } from './actions-ticket/getTicketResponseMarkdown'
import { getTicketSystemTags } from './actions-ticket/getTicketSystemTags'
import { getTicketUserConsent } from './actions-ticket/getTicketUserConsent'
import { getTicketUtteranceText } from './actions-ticket/getTicketUtteranceText'
import { getTicketVerbatim } from './actions-ticket/getTicketVerbatim'
import { updateTicket } from './actions-ticket/updateTicket'

export const DashStoreActions = {
  // 📎 Attachment
  uploadImage: uploadImage,
  getAttachment: getAttachment,

  // 🧱 Basic
  getFilterTags: getFilterTags,
  getProducts: getProducts,

  // 🏢 Basic > Tenant
  getTenant: getTenant,
  searchM365Tenants: searchM365Tenants,

  // 🧑‍💻 Basic > User
  getUserProfile: getUserProfile,
  getUserPhoto: getUserPhoto,

  // 💭 Comments
  getCommentCount: getCommentCount,
  getComments: getComments,
  updateComment: updateComment,
  createComment: createComment,
  deleteComment: deleteComment,

  // 🤖 Feedback Copilot
  getFCConversationByUserId: getFCConversationByUserId,
  appendFCConversation: appendNewFCConversation,
  submitFCRagQuery: submitFCRagQuery,
  updateFCConversation: updateFCConversation,
  updateUserReadFCRagResult: updateUserReadFCRagResult,

  // 📈 MCP
  getMcpExperimentFeedback: getMcpExperimentFeedback,

  // 📦 Raw > Ticket > Chat
  getRawConversation: getRawConversation,
  getRawConversationGroup1: getRawConversationGroup1,
  getRawConversationGroup2: getRawConversationGroup2,
  getRawConversationGroup3: getRawConversationGroup3,
  getRawConversationGroup4: getRawConversationGroup4,

  // 📦 Raw > Ticket > Chat > 3S Online
  getRaw3sOnlineDataGroup1: getRaw3sOnlineDataGroup1,
  getRaw3sOnlineDataGroup2: getRaw3sOnlineDataGroup2,
  getRaw3sOnlineDataGroup3: getRaw3sOnlineDataGroup3,
  getRaw3sOnlineDataGroup4: getRaw3sOnlineDataGroup4,

  // 📦 Raw > Ticket > Chat > 3S Offline
  getRaw3sOfflineDataGroup1: getRaw3sOfflineDataGroup1,
  getRaw3sOfflineDataGroup2: getRaw3sOfflineDataGroup2,
  getRaw3sOfflineDataGroup3: getRaw3sOfflineDataGroup3,
  getRaw3sOfflineDataGroup4: getRaw3sOfflineDataGroup4,

  // 🔍 Search
  searchTickets: searchTickets,

  // 🔍 Search > Queries
  updateQuery: updateQuery,
  getTeamQueries: getTeamQueries,
  getMyQueries: getMyQueries,
  getQuery: getQuery,
  deleteQuery: deleteQuery,

  // 👥 Team
  getTeamAreasAndRootCauses: getTeamAreasAndRootCauses,
  getTeamIssueClusteringBatchInfoList: getTeamIssueClusteringBatchInfoList,
  getTeamIssueClusteringInfoList: getTeamIssueClusteringInfoList,
  getTeams: getTeams,
  getAssignedTeamMembers: getAssignedTeamMembers,
  getTeamDRIListByTeamId: getTeamDRIListByTeamId,
  setTeamDRIList: setTeamDRIList,
  batchUpdateTicketStatusInfo: batchUpdateTicketStatusInfo,
  updateTicketStatusInfo: updateTicketStatusInfo,

  // 🐞 Team > Root Cause
  getTeamRootCauses: getTeamRootCauses,
  getRootCauseListByTeam: getRootCauseListByTeam,
  updateTicketRootCause: updateTicketRootCause,
  getOrFetchRootCauseListByTeam: getOrFetchRootCauseListByTeam,
  searchRootCauseByTicketCharacter: searchRootCauseByTicketCharacter,
  getTicketRootCause: getTicketRootCause,
  updateRootCause: updateRootCause,
  createRootCause: createRootCause,
  getRootCauseInfoById: getOrFetchRootCauseInfoByRootCauseId,
  appendRootCauseByTeam: appendRootCauseByTeam,
  updateRootCauseByTeam: updateRootCauseByTeam,

  // 🥇 Team > Top Issues
  getOrFetchTeamIssueClusteringBatchInfoList: getOrFetchTeamIssueClusteringBatchInfoList,
  getOrFetchTeamIssueClusteringInfoList: getOrFetchTeamIssueClusteringInfoList,
  getTopIssueExtendInfo: getTopIssueExtendInfo,
  removeTicketFromTopIssue: removeTicketFromTopIssue,

  // 🐞 Ticket
  getRawV1Ticket: getRawV1Ticket, // TODO: Needs to be removed
  getTicket: getTicket,
  getTicketContext: getTicketContext,
  updateTicket: updateTicket,
  getTicketOcv: getRawOcvTicket,
  getRawOdsTicketInteractions: getRawOdsTicketInteractions,
  getTicketUtteranceText: getTicketUtteranceText,
  getTicketResponseMarkdown: getTicketResponseMarkdown,
  getTicketVerbatim: getTicketVerbatim,
  getTicketEmail: getTicketEmail,
  getTicketMetadata: getTicketMetadata,
  getTicketEnvironment: getTicketEnvironment,
  getTicketMessageIds: getTicketMessageIds,
  getTicketIdByWorkItemId: getTicketIdByWorkItemId,
  getTicketUserConsent: getTicketUserConsent,

  // 🕒 Ticket > Activity History
  getTicketActivityHistory: getTicketActivityHistory,

  // 💾 Ticket > Blob File
  getTicketSource: getTicketSource,

  // 🔀 Ticket > CallFlow
  getCallFlow: getCallFlow,
  getTelemetryMetrics: getTelemetryMetrics,

  // 🗣️ Ticket > Chat
  getTicketLastTurnMessageId: getTicketLastTurnMessageId,
  getTicketChatTurnCitations: getTicketChatTurnCitations,
  getTicketTurnMetadata: getTicketTurnMetadata,
  getTicketTurnConversation: getTicketTurnConversation,

  // 🗣️ Ticket > Chat > 3S
  get3sLogs: get3sLogs,
  get3sOnlineGeneralDiagnosticData: get3sOnlineGeneralDiagnosticData,
  get3sOfflineGeneralDiagnosticData: get3sOfflineGeneralDiagnosticData,

  // 📜 Ticket > Kusto
  get3SLatencyLogs: get3SLatencyLogs,
  get3sGwsLogs: get3sGwsLogs,
  getExtensibilityLog: getExtensibilityLog,
  getStateDurationLog: getStateDurationLog,
  getTraceLogs: getTraceLogs,
  getPerformanceLogs: getPerformanceLogs,
  getLlmLogs: getLlmLogs,

  // 🩺 Ticket > SymptomReport
  getTicketHealthData: getTicketHealthData,
  getSymptomReport: getSymptomReport,

  // 🏷️ Ticket > Tags
  getTicketSystemTags: getTicketSystemTags,
  getAllCustomTags: getAllCustomTags,
  getTicketCustomTags: getTicketCustomTags,
  getBatchTicketsCustomTags: getBatchTicketsCustomTags,
  updateAllCustomTags: updateAllCustomTags,
  updateTicketCustomTag: updateTicketCustomTag,
} satisfies {
  readonly [name: string]: (context: IDashStoreContext, ...args: never[]) => PromiseSnapshot<unknown> | Promise<unknown>
}
