import { TimeRange, Times } from '@copilot-dash/core'
import 'react-multi-date-picker/styles/colors/yellow.css'
import { DateTimeRangePicker } from '../DateTimeRangePicker/DateTimeRangePicker'
import { Column } from '../Layout'
import { useMemo } from 'react'
import { makeStyles, shorthands } from '@fluentui/react-components'

interface IProps {
  range: TimeRange | undefined
  onChanged: (range: TimeRange) => void
}

export function TimeFilter({ range, onChanged }: IProps) {
  const styles = useStyles()
  const timezone = app.settings.timezone.use()

  // Define the date range for the date/time picker, adjusted for the user's preferred timezone.
  const [minDate, maxDate] = useMemo(() => {
    // `minDate` is set to March 18, 2024, as there is no feedback diagnostic data available before this date (in UTC).
    // `maxDate` is set to the current date.
    // Customers are restricted from selecting a date prior to March 18, 2024, or beyond the current date.
    return [Times.startOfDay(new Date('2024-03-18'), timezone), Times.endOfDay(new Date(), timezone)]
  }, [timezone])

  return (
    <Column className={styles.root}>
      <DateTimeRangePicker range={range} onChanged={onChanged} minDate={minDate} maxDate={maxDate} />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    justifyItems: 'start',
    ...shorthands.gap('2px'),
  },
})
