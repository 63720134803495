import { Control, Controller } from 'react-hook-form'
import {
  HasCitationFilter,
  HasEntityCardFilter,
  HasErrorMessagesFilter,
  IsApologyFilter,
  ResponseHeroTypeFilter,
  ResponseLinkTypeFilter,
} from '../filters'

import { Body1Stronger } from '@fluentui/react-components'
import { Card } from './Card'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { useMemo } from 'react'
import { useStyles } from './Card.styles'
import { useTicketFilterStore } from '../../store/store'
import { ResponseDetailsCardFilters } from './config'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function ResponseDetailsCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const hasResponseDetailsFilters = useMemo(() => {
    return filterPanelItems.some((item) => Object.values(ResponseDetailsCardFilters).includes(item))
  }, [filterPanelItems])

  if (!hasResponseDetailsFilters) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Response details </Body1Stronger>
      <Card className={styles.cardFeedbackTag}>
        {/* Is apology */}
        {filterPanelItems?.includes(SearchFilterFieldNames.isApology) && (
          <Controller
            name="isApology"
            control={control}
            render={({ field }) => <IsApologyFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
        {/* Has citation */}
        {filterPanelItems?.includes(SearchFilterFieldNames.hasCitation) && (
          <Controller
            name="hasCitation"
            control={control}
            render={({ field }) => <HasCitationFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}
        {/* Has Error Messages  */}
        {filterPanelItems.includes(SearchFilterFieldNames.hasErrorMessages) && (
          <Controller
            name="hasErrorMessages"
            control={control}
            render={({ field }) => (
              <HasErrorMessagesFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}

        {/* Has entity representation */}
        {filterPanelItems?.includes(SearchFilterFieldNames.hasEntityCard) && (
          <Controller
            name="hasEntityCard"
            control={control}
            render={({ field }) => <HasEntityCardFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {/* Response hero type */}
        {filterPanelItems?.includes(SearchFilterFieldNames.responseHeroType) && (
          <Controller
            name="responseHeroType"
            control={control}
            render={({ field }) => (
              <ResponseHeroTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}
        {/* Response link type */}
        {filterPanelItems?.includes(SearchFilterFieldNames.responseLinkType) && (
          <Controller
            name="responseLinkType"
            control={control}
            render={({ field }) => (
              <ResponseLinkTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />
            )}
          />
        )}
      </Card>
    </section>
  )
}
