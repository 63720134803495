import {
  ApiTicketAdditionalContext,
  ApiTicketAdditionalContextItem,
  apiTicketAdditionalContextSchema,
} from '@copilot-dash/api'
import { ITicketAdditionalContext, ITicketAdditionalContextItem } from '@copilot-dash/domain'

const REDACT_PROPERT_LIST = ['SaraUrl', 'DiagnosticsUrl']

export class TicketAdditionalContextConverter {
  static readonly fromApiString = (api: string) => {
    const apiObject = apiTicketAdditionalContextSchema.parse(JSON.parse(api))
    return this.fromApi(apiObject)
  }

  private static fromApi(api: ApiTicketAdditionalContext): ITicketAdditionalContext {
    return Object.entries(api).reduce((additionalContext: ITicketAdditionalContext, [key, value]) => {
      if (!REDACT_PROPERT_LIST.includes(key)) {
        return {
          ...additionalContext,
          [key]: value ? this.fromApiItem(value) : undefined,
        }
      }

      return additionalContext
    }, {} as ITicketAdditionalContext)
  }

  private static fromApiItem(api: ApiTicketAdditionalContextItem): ITicketAdditionalContextItem {
    return {
      type: api.Type,
      result: api.Result,
      resultObject: api.Result ? tryParseJson(api.Result) : undefined,
    }
  }
}

function tryParseJson(str: string): object | undefined {
  try {
    return JSON.parse(str)
  } catch (_) {
    return undefined
  }
}
